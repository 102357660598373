import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlantationTableComponent } from '../summary-dashboard/plantation-table/plantation-table.component';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { CrdStateService } from '../services/state-service/crd-state.service';
import { PrimengExportsModule } from '../primeng-exports.module';
import { DatasetAutocompleteComponent } from '../shared/dataset-autocomplete/dataset-autocomplete.component';
import { SiLinkingSummaryPageComponent } from './si-linking-summary-page/si-linking-summary-page.component';
import { Plantation } from '../models/crd-state.interface';
import { PLANTATION_LIST_TABLE_COLUMNS } from '../constants/table-columns.const';
import { DashboardService } from '../services/data-service/dashboard.service';
import { BehaviorSubject, combineLatest, Subject, takeUntil } from 'rxjs';
import { SiLinkingPlantationsPayload } from '../models/si-linking-payload.model';
import { UtilityService } from '../services/utility.service';
import { PostMessageTypesEnum } from '../enums/post-message-type.enum';
import { TranslocoPipe } from '@jsverse/transloco';
import { EventStateService } from '../services/state-service/event-state.service';
import { ProgressSpinnerComponent } from '../shared/progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-si-linking-page',
  standalone: true,
  imports: [
    CommonModule,
    PlantationTableComponent,
    PageHeaderComponent,
    PrimengExportsModule,
    DatasetAutocompleteComponent,
    SiLinkingSummaryPageComponent,
    TranslocoPipe,
    ProgressSpinnerComponent
  ],
  templateUrl: './si-linking-page.component.html',
  styleUrls: ['./si-linking-page.component.scss']
})
export class SiLinkingPageComponent implements OnInit, OnDestroy {
  // TODO: update translation
  header = 'DASHBOARD.SI_LINKING.LINK_PLANTATION_TO_SI';
  isFinalPage = false;
  columns = PLANTATION_LIST_TABLE_COLUMNS;
  destroyed$ = new Subject<void>();

  get primaryActionLabel() {
    return this.isFinalPage
      ? 'DASHBOARD.SI_LINKING.CONFIRM'
      : 'DASHBOARD.SI_LINKING.NEXT';
  }

  selectedPlantations: Plantation[] = [];
  payload!: SiLinkingPlantationsPayload;
  disableBackButton$ = new BehaviorSubject(false);
  disablePrimaryButton$ = new BehaviorSubject(true);
  isSiLinkInProgress$ = new Subject<boolean>();

  constructor(
    public crdStateService: CrdStateService,
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    public eventStateService: EventStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeColumns();
    this.constructLinkToSiPayload();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeColumns() {
    this.columns = [
      ...this.columns.filter((column) => column.field !== 'actions')
    ];
  }

  constructLinkToSiPayload() {
    combineLatest([
      this.crdStateService.geometryData$,
      this.crdStateService.selectedPlantationList$,
      this.crdStateService.siDetails$
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([geometryData, selectedPlantations, siDetails]) => {
        if (geometryData.length && selectedPlantations.length && siDetails) {
          const area = [
            ...this.selectedPlantations.map((item) => ({
              key: item.plantation_code,
              value: +item.area_calculated
            }))
          ];
          const plantations = [
            ...geometryData.map((data: any) => ({
              plantation_code: data.plantation_code,
              source:
                this.selectedPlantations.find(
                  (item) => item.plantation_code === data.plantation_code
                )?.data_source || '',
              name: data.plantation_code,
              geometry: {
                type: data._geo.type,
                coordinates: data._geo.coordinates
              },
              area: area.find((area) => area.key === data.plantation_code)
                ?.value,
              _docid: this.selectedPlantations.find(
                (item) => item.plantation_code === data.plantation_code
              )?._docid
            }))
          ];
          this.payload = {
            sidetail_id: siDetails?.siDetailId.toString(),
            plantations: plantations
          };
        }
      });
  }

  onBackClicked() {
    if (!this.isFinalPage) {
      this.utilityService.emitPropToParent(
        'type',
        PostMessageTypesEnum.REDIRECT_TRACEABILITY
      );
    }

    this.isFinalPage = false;
  }

  onPrimaryActionClicked() {
    if (!this.isFinalPage) {
      this.crdStateService.setSelectedPlantationList(this.selectedPlantations);
    } else {
      this.disablePrimaryButton$.next(true);
      this.disableBackButton$.next(true);
      this.isSiLinkInProgress$.next(true);
      this.dashboardService
        .linkPlantationToSi(this.payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.utilityService.emitPropToParent(
              'type',
              PostMessageTypesEnum.REDIRECT_TRACEABILITY
            );

            this.utilityService.emitToast({
              message: 'Linked successfully',
              isSuccess: true
            });
          },
          error: () => {
            this.utilityService.emitToast({
              message: 'Something went wrong. Try again later.',
              isSuccess: false
            });
          },
          complete: () => {
            this.disablePrimaryButton$.next(false);
            this.disableBackButton$.next(false);
            this.isSiLinkInProgress$.next(false);
          }
        });
    }

    this.isFinalPage = true;
  }

  onPlantationSelectionChanged(plantations: Plantation[]) {
    this.selectedPlantations = plantations;
    this.disablePrimaryButton$.next(!this.selectedPlantations.length);
  }

  checkSummaryTableLoading(isLoading: boolean) {
    this.disableBackButton$.next(isLoading);
    this.disablePrimaryButton$.next(isLoading);
    this.cdr.detectChanges();
  }
}
