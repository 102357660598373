<div id="map">
  <ng-container
    *ngIf="!(crdStateService.mapStyle$ | async)?.length; else controls"
  >
    <app-progress-bar-skeleton [width]="'100%'" [height]="'100%'">
    </app-progress-bar-skeleton>
  </ng-container>
  <ng-template #controls>
    <app-layers-map-control></app-layers-map-control>
    <!-- TODO: implement feature flagging -->
    <!-- <app-risk-buffer-toggle></app-risk-buffer-toggle> -->
  </ng-template>
</div>
