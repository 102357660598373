<p-autoComplete
  #datasetAutocomplete
  [ngModel]="selectedDataset"
  [dropdown]="true"
  [suggestions]="filteredDatasetOptions"
  (completeMethod)="filterDataset($event)"
  (onSelect)="onSelectedDatasetChanged($event)"
  (onBlur)="onDatasetOptionsBlur($event)"
  field="description"
  [minLength]="0"
  [disabled]="disabled"
></p-autoComplete>
