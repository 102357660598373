<span
  [pTooltip]="getErrorIconTooltip(errorType)"
  tooltipPosition="bottom"
  [tooltipDisabled]="!hasTooltip"
  class="material-symbols-outlined"
  [class]="marginClass"
  style="margin-top: 0.3rem"
  [ngClass]="{
    'warning-icon': errorType === errorTypes.WARNING,
    'error-icon': errorType === errorTypes.ERROR,
    clickable: isClickable
  }"
>
  error
</span>
