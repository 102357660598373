<div *ngIf="riskOverlapDetail" class="flex card-content flex-column">
  <div class="flex align-items-center mb-4">
    <app-risk-icon
      [marginClass]="'mr-2'"
      [hasTooltip]="false"
      [iconColor]="riskOverlapDetail.color"
    ></app-risk-icon>
    <span class="header">{{ riskOverlapDetail.label | transloco }}</span>
  </div>

  <span class="risk-description-header"
    >{{ 'MAP_PAGE.RISK_DESCRIPTION' | transloco }}:</span
  >

  <p class="risk-description-content">
    {{ riskOverlapDetail.description }}
  </p>
</div>
