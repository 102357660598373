import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrdStateService } from '../../services/state-service/crd-state.service';
import { PrimengExportsModule } from '../../primeng-exports.module';
import { FormsModule } from '@angular/forms';
import { Plantation } from '../../models/crd-state.interface';
import { Subject, Subscription, combineLatest, map, takeUntil } from 'rxjs';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';
import { UtilityService } from 'src/app/services/utility.service';
import { RiskLoaderComponent } from 'src/app/shared/risk-loader/risk-loader.component';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-selected-plantations-panel',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    ProgressBarSkeletonComponent,
    RiskLoaderComponent,
    RiskIconComponent,
    TranslocoPipe
  ],
  templateUrl: './selected-plantations-panel.component.html',
  styleUrls: ['./selected-plantations-panel.component.scss']
})
export class SelectedPlantationsPanelComponent implements OnInit, OnDestroy {
  selectedPlantations: Plantation[] = [];
  destroyed$ = new Subject<void>();
  isRiskLayersCalled = false;
  riskTypes = RiskTypesEnum;
  riskLayerSub$!: Subscription;

  get plantationDetails(): { label: string; field: keyof Plantation }[] {
    return [
      {
        label: this.translateDetailLabel('LAND_AREA'),
        field: 'area_calculated'
      },
      {
        label: this.translateDetailLabel('PROVINCE'),
        field: 'adm_1'
      },
      {
        label: this.translateDetailLabel('DISTRICT'),
        field: 'adm_2'
      },
      {
        label: this.translateDetailLabel('COUNTRY'),
        field: 'country'
      },
      {
        label: this.translateDetailLabel('SOURCE'),
        field: 'data_source'
      },

      {
        label: this.translateDetailLabel('DATE_CREATED'),
        field: 'date_created'
      }
    ];
  }
  constructor(
    private crdStateService: CrdStateService,
    public utilityService: UtilityService,
    public eventStateService: EventStateService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initializeSelectedPlantations();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.riskLayerSub$.unsubscribe();
  }

  initializeSelectedPlantations() {
    combineLatest([
      this.crdStateService.geometryData$,
      this.crdStateService.selectedPlantationList$
    ])
      .pipe(
        map(([geometryData, selectedPlantations]) => {
          let mappedPlantations = [];
          if (geometryData.length) {
            mappedPlantations = selectedPlantations.map((plantation) => {
              const polygon = geometryData.find(
                (polygon) =>
                  polygon.plantation_code === plantation.plantation_code
              );

              return {
                ...plantation,
                mapData: polygon?._geo
              };
            }) as Plantation[];

            return mappedPlantations;
          }
          return [];
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((res) => {
        if (res.length) {
          this.selectedPlantations = [...res] as Plantation[];
          // TODO: move to event state service
          this.crdStateService.setCheckedSelectedPlantationList([
            ...this.selectedPlantations
          ]);
          if (!this.isRiskLayersCalled) {
            this.riskLayerSub$ = this.crdStateService.getRiskLayers();
            this.isRiskLayersCalled = true;
          }
        }
      });
  }

  onPlantationChecked() {
    this.crdStateService.setCheckedSelectedPlantationList([
      ...this.selectedPlantations
    ]);
  }

  onToggleExpand(plantation: Plantation) {
    plantation.isExpanded = !plantation.isExpanded;
  }

  onZoomPlantation(plantation: Plantation) {
    this.eventStateService.zoomPlantation = plantation;
  }

  translateDetailLabel(label: string) {
    return this.translocoService.translate(`MAP_PAGE.${label}`);
  }
}
