import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { SETTINGS_LABELS } from 'src/app/constants/settings-labels.const';
import { SettingsModalContent } from 'src/app/models/settings-modal-content.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-settings-modal',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule, FormsModule],
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit {
  get screen() {
    return this.dialogConfig.data;
  }

  isDoNotShowAgain = false;
  isConfirmInProgress = false;
  screens = ScreenEnum;
  modalContent: SettingsModalContent = SETTINGS_LABELS.confirmation;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private crdStateService: CrdStateService
  ) {}

  ngOnInit(): void {
    this.initializeContent();
  }

  initializeContent() {
    if (this.screen === ScreenEnum.SETTINGS) {
      this.modalContent = SETTINGS_LABELS.confirmation;
    } else {
      this.modalContent = SETTINGS_LABELS.redirect;
    }
  }

  onBackClicked() {
    this.dynamicDialogRef.close();
  }

  onConfirmClicked() {
    this.isConfirmInProgress = true;
    this.dynamicDialogRef.close();
    if (this.screen !== ScreenEnum.SETTINGS) {
      this.crdStateService.setCurrentScreen(ScreenEnum.SETTINGS);
    } else {
      this.crdStateService.setCurrentScreen(ScreenEnum.DASHBOARD);
    }
  }
}
