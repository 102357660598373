import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  UserCustomConfig,
  UserStyle
} from '../models/user-custom-config.model';
import { from, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserConfigService {
  getUserConfig(tenantId: string) {
    const config = environment.USER_CONFIG.replace('{tenantId}', tenantId);
    return from(
      fetch(config)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then<UserCustomConfig>((data) => data)
    );
  }

  setTheme(tenantId: string) {
    this.getUserConfig(tenantId)
      .pipe(map((config) => config.style))
      .subscribe((style: UserStyle) => {
        for (const [key, value] of Object.entries(style.colors)) {
          this.setColor(this.formatToCssVar(key), value);
        }
      });
  }

  setColor(property: string, value: string) {
    document.documentElement.style.setProperty(property, value);
  }

  formatToCssVar(str: string): string {
    return `--${str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}`;
  }

  setCnEnvironmentConfig() {
    environment.CRD_API = environment.CRD_API_CN;
    environment.TRACEABILITY_API = environment.TRACEABILITY_API_CN;
  }
}
