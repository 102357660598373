import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileDndDirective } from 'src/app/directives/file-dnd.directive';
import { FileSizePipe } from 'src/pipes/file-size.pipe';
import { ImportTabComponent } from './import-tab/import-tab.component';
import { MatchTabComponent } from './match-tab/match-tab.component';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-upload-file-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FileDndDirective,
    FileSizePipe,
    ImportTabComponent,
    MatchTabComponent,
    TranslocoPipe
  ],
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss']
})
export class UploadFileModalComponent implements OnInit, OnDestroy {
  hasError = false;
  isMatchTabDisabled = true;
  activeIndex = 0;

  get acceptedFileTypes() {
    return ['.csv', '.xls', '.xlsx'];
  }

  get formattedAcceptedFileTypes() {
    return this.acceptedFileTypes.join(',');
  }

  constructor(
    private dialogRef: DynamicDialogRef,
    private crdStateService: CrdStateService,
    private eventStateService: EventStateService
  ) {}

  ngOnInit(): void {
    this.activeIndex = 0;
  }

  ngOnDestroy(): void {
    this.eventStateService.closeUploadModal = false;
  }

  onBackClicked() {
    this.dialogRef.close();
  }

  isFileInvalid(isFileInvalid: boolean) {
    this.isMatchTabDisabled = isFileInvalid;
  }

  nextTabClicked() {
    this.activeIndex = 1;
    this.getColumns();
  }

  onTabChanged(index: number) {
    this.activeIndex = index;
    if (index === 0) {
      this.crdStateService.resetUploadFileMappingColumns();
      this.eventStateService.isUploadEditInProgress = false;
      return;
    }
    this.getColumns();
  }

  getColumns() {
    this.eventStateService.matchColumnsLoading = true;
    this.crdStateService.getUploadFileMappingColumns();
  }
}
