import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';

@Component({
  selector: 'app-progress-spinner',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule],
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent {}
