<span
  [pTooltip]="label"
  tooltipPosition="bottom"
  [tooltipDisabled]="!hasTooltip"
  class="material-symbols-outlined action-icon warning-icon"
  [class]="marginClass"
  [ngStyle]="{ color: iconColor }"
>
  warning
</span>
