import { environment } from 'src/environments/environment';
import { TranslocoHttpLoader } from './services/transloco-http-loader.service';
import { Provider, APP_INITIALIZER } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
  initializeEnvironmentConfig,
  preloadLanguage,
  preloadTheme
} from './app-initializer';
import { UserConfigService } from './services/user-config.service';

export const TRANSLOCO_CONFIG = {
  config: {
    availableLangs: ['engb', 'id', 'cn'],
    defaultLang: 'engb',
    fallbackLang: 'engb',
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: environment.production
  },
  loader: TranslocoHttpLoader
};

export const APP_INITIALIZERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: preloadLanguage,
    deps: [TranslocoService]
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: preloadTheme,
    deps: [UserConfigService]
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: initializeEnvironmentConfig,
    deps: [UserConfigService]
  }
];
