<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="flex mb-3">
  <span class="text-xl font-semibold"
    >{{ headerLabel | transloco }} <ng-content select="[specialLabel]"></ng-content
  ></span>
  <!-- TODO: implement feature flagging -->
  <!-- <span
    *ngIf="(crdStateService.currentScreen$ | async) === screens.DASHBOARD"
    class="ml-2 settings material-symbols-outlined"
    (click)="onSettingsClicked()"
  >
    settings
  </span> -->
</div>
