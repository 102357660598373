import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';

@Component({
  selector: 'app-progress-bar-skeleton',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule],
  templateUrl: './progress-bar-skeleton.component.html',
  styleUrls: ['./progress-bar-skeleton.component.scss']
})
export class ProgressBarSkeletonComponent {
  @Input() width = '100%'
  @Input() height = '100%'

}
