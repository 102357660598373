import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { INITIAL_SELECTED_PERIOD } from 'src/app/models/crd-state.interface';
import { UtilityService } from 'src/app/services/utility.service';
import { Subject, takeUntil } from 'rxjs';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';

@Component({
  selector: 'app-si-linking-summary-page',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RiskIconComponent,
    TranslocoPipe
  ],
  templateUrl: './si-linking-summary-page.component.html',
  styleUrls: ['./si-linking-summary-page.component.scss']
})
export class SiLinkingSummaryPageComponent implements OnInit, OnDestroy {
  @Input({ required: true }) columns!: ColumnDefinition[];
  @Input() selectedItems: any = [];
  @Output() tableLoading = new EventEmitter<boolean>();

  destroyed$ = new Subject<void>();
  tableDataKey = 'plantation_code';
  riskTypes = RiskTypesEnum;
  isTableLoading = true;

  constructor(
    private eventStateService: EventStateService,
    public crdStateService: CrdStateService,
    public utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.assignGeometryData();
    this.checkIsTableLoading();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  checkIsTableLoading() {
    this.eventStateService.isGeometryDataLoading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isLoading) => {
        this.isTableLoading = isLoading;
        this.tableLoading.emit(this.isTableLoading);
      });
  }

  assignGeometryData() {
    const plantationCodes = this.utilityService.getPlantationCodesArray(
      this.selectedItems,
      'plantation_code'
    );
    this.crdStateService.getGeometryData(
      {
        plantationCodes: plantationCodes,
        period: INITIAL_SELECTED_PERIOD
      },
      true
    );
  }
}
