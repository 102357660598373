import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  get userInfoToken() {
    return sessionStorage.getItem('ARP_Token');
  }

  set userInfoToken(token: string | null) {
    sessionStorage.setItem('ARP_Token', token || '');
  }

  constructor() {
    // TODO - add checking if embedded or standalone
    setInterval(() => {
      sessionStorage.removeItem('ARP_Token');
    }, 1000 * 60 * 60 * 8);
  }
}
