import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ToastProps } from '../models/toast.model';
import { DialogService } from 'primeng/dynamicdialog';
import { ScreenEnum } from '../enums/screens.enum';
import { SettingsModalComponent } from '../settings-page/settings-modal/settings-modal.component';
import { SettingsModalHeadersEnum } from '../enums/settings-modal-headers.enum';
import { GeoJsonTypesEnum } from '../enums/geojson-types.enum';
import { PostMessageTypesEnum } from '../enums/post-message-type.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private dialogService: DialogService) {}

  getPlantationCodesArray(plantationList: any[], plantationCodeProp: string) {
    const plantationCodes = new Set<string>();
    plantationList.forEach((plantation) => {
      plantationCodes.add(plantation[plantationCodeProp]);
    });
    return Array.from(plantationCodes);
  }

  constructMultiplePlantationsQuery(plantationCodes: string[]) {
    let queryParams = '';
    plantationCodes.forEach((code, index) => {
      if (index === 0) {
        queryParams += `?plantation_code=${code}`;
      } else {
        queryParams += `&plantation_code=${code}`;
      }
    });

    return queryParams;
  }

  getFileNameFromHeaders(response: HttpResponse<Blob>) {
    const contentDisposition =
      response.headers.get('content-disposition') || '';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let fileName = 'download';
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }

    return fileName;
  }

  emitPropToParent<T, U>(key1: string, value1: T, key2?: string, value2?: U) {
    const message = {
      ...(key1 && { [key1]: value1 }),
      ...(key2 && { [key2]: value2 })
    };

    window.parent.postMessage(message, '*');
  }

  emitToast(toast: ToastProps) {
    this.emitPropToParent('type', PostMessageTypesEnum.TOAST, 'toast', toast);
  }

  openSettingsModal(header: SettingsModalHeadersEnum, screen: ScreenEnum) {
    this.dialogService.open(SettingsModalComponent, {
      header: header,
      width: '45rem',
      data: screen,
      contentStyle: { padding: 0 },
      styleClass: 'settings-modal'
    });
  }

  getComputedStyle(value: string) {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(value)
      .trim();
  }

  getGeoJsonTypeProps(value: GeoJsonTypesEnum) {
    const props = {
      label: '',
      icon: ''
    };
    switch (value?.toLowerCase() || '') {
      case GeoJsonTypesEnum.ST_MULTIPOLYGON:
        props.label = 'Multi Polygon';
        props.icon = 'layers';
        break;
      case GeoJsonTypesEnum.ST_POLYGON:
        props.label = 'Polygon';
        props.icon = 'layers';
        break;
      case GeoJsonTypesEnum.ST_GEOMETRY_COLLECTION:
        props.label = 'Geometry Collection';
        props.icon = 'layers';
        break;
      case GeoJsonTypesEnum.ST_POINT:
        props.label = 'Point';
        props.icon = 'location_on';
        break;

      case GeoJsonTypesEnum.OTHERS:
        props.label = 'Others';
        props.icon = 'more_horiz';
        break;
      case GeoJsonTypesEnum.INVALID_GEOMETRY:
        props.label = 'Invalid Geometry';
        props.icon = 'warning';
        break;
      default:
        props.label = 'Invalid Geometry';
        props.icon = 'warning';
        break;
    }

    return props;
  }
}
