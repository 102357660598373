import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { CrdStateService } from '../services/state-service/crd-state.service';
import { ScreenEnum } from '../enums/screens.enum';
import { RefreshButtonComponent } from '../shared/refresh-button/refresh-button.component';
import { PrimengExportsModule } from '../primeng-exports.module';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { UtilityService } from '../services/utility.service';
import { SettingsModalHeadersEnum } from '../enums/settings-modal-headers.enum';

@Component({
  selector: 'app-settings-page',
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    RefreshButtonComponent,
    PrimengExportsModule,
    ReactiveFormsModule
  ],
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {
  get gpsLocation() {
    return this.settingsFormGroup.get('gpsLocation') as FormControl;
  }

  settingsFormGroup!: FormGroup;

  gpsLocationOptions = [
    {
      label: 'In the middle of the plantation',
      value: '1'
    },
    {
      label: 'At the edge of the plantation',
      value: '2'
    },
    {
      label: 'Unknown',
      value: '3'
    }
  ];

  constructor(
    private crdStateService: CrdStateService,
    private fb: FormBuilder,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.initializeSettingsFormGroup();
  }

  initializeSettingsFormGroup() {
    this.settingsFormGroup = this.fb.group({
      gpsLocation: ['', Validators.required]
    });
  }

  onBackClicked() {
    this.crdStateService.setCurrentScreen(ScreenEnum.DASHBOARD);
  }

  onCancelClicked() {
    this.onBackClicked();
  }
  onSaveChangesClicked() {
    this.utilityService.openSettingsModal(
      SettingsModalHeadersEnum.CONFIRMATION,
      ScreenEnum.SETTINGS
    );
  }
}
