<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<ng-container *ngIf="!(eventStateService.isStatisticsUpdating$ | async)">
  <div class="grid">
    <div class="col-3">
      <ng-container
        *ngIf="
          !(eventStateService.isTotalSummaryLoading$ | async);
          else loading
        "
      >
        <p-card class="summary-card">
          <div class="flex flex-column mx-3">
            <span class="text-xl mb-3 font-semibold">{{
              'DASHBOARD.STATISTICS.TOTAL_PLOTS' | transloco
            }}</span>
            <span class="text-3xl font-bold">{{
              totalPlotsSum | number : '1.0-0'
            }}</span>
            <p-divider />
          </div>
          <ng-container *ngFor="let plot of totalPlots">
            <div
              class="flex justify-content-between align-items-center my-3 px-5"
            >
              <span class="text-left text-lg flex align-items-center">
                <span class="material-symbols-outlined mr-2 geojson-type-icon">
                  {{ plot.icon }} </span
                >{{ plot.label }}</span
              >
              <span class="text-3xl font-bold">{{
                plot.count | number : '1.0-0'
              }}</span>
            </div>
          </ng-container>
        </p-card>
      </ng-container>
    </div>
    <div class="col-9">
      <ng-container
        *ngIf="
          !(eventStateService.isTotalSummaryLoading$ | async);
          else loading
        "
      >
        <p-card class="summary-card">
          <div class="flex flex-column">
            <div class="flex mx-3 justify-content-between align-items-center">
              <span class="text-xl mb-3 font-semibold">{{
                'DASHBOARD.STATISTICS.TOTAL_LAND_AREA_HA' | transloco
              }}</span>
              <span class="text-lg mb-3"
                >{{
                  'DASHBOARD.STATISTICS.ESTIMATED_YIELD_PER_YEAR' | transloco
                }}: {{ totalYield | number : '1.2-2' }} MT</span
              >
            </div>
            <span class="text-3xl font-bold mx-3"
              >{{ totalLandAreasSum | number : '1.2-2' }} ha</span
            >
            <p-divider />
            <div class="total-land-area-chart">
              <p-chart
                type="bar"
                [data]="totalLandAreaData"
                [options]="totalLandAreaOptions"
              />
            </div>
          </div>
        </p-card>
      </ng-container>
    </div>
  </div>
  <div class="grid my-2">
    <div class="col-12">
      <ng-container
        *ngIf="
          !(eventStateService.isTotalSummaryLoading$ | async);
          else loading
        "
      >
        <p-accordion
          class="mapping-efforts"
          expandIcon="pi pi-chevron-down"
          collapseIcon="pi pi-chevron-up"
          [activeIndex]="expandMappingEfforts ? 0 : -1"
          (onOpen)="expandMappingEfforts = true"
          (onClose)="expandMappingEfforts = false"
        >
          <p-accordionTab
            header="{{ 'DASHBOARD.STATISTICS.MAPPING_EFFORTS' | transloco }}"
            class=""
            iconPos="end"
          >
            <div class="flex flex-column">
              <span class="text-sm mx-3 mb-3">{{
                'DASHBOARD.STATISTICS.NO_OF_PLOTS_MAPPED' | transloco
              }}</span>
              <div style="padding-left: 0.75rem">
                <p-chart
                  type="line"
                  [data]="totalMappingEffortData"
                  [options]="totalMappingEffortOptions"
                />
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="eventStateService.isStatisticsUpdating$ | async">
  <div class="grid">
    <div class="col-3">
      <ng-template [ngTemplateOutlet]="updateLoading"></ng-template>
    </div>
    <div class="col-9">
      <ng-template [ngTemplateOutlet]="updateLoading"></ng-template>
    </div>
  </div>

  <div class="grid">
    <div class="col-12">
      <ng-template [ngTemplateOutlet]="updateLoading"></ng-template>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <p-skeleton width="100%" height="14rem"></p-skeleton>
</ng-template>

<ng-template #updateLoading>
  <app-progress-bar-skeleton [height]="'21rem'"></app-progress-bar-skeleton>
</ng-template>
