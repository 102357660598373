<div
  class="flex flex-column align-items-center justify-content-between delete-modal"
>
  <ng-container
    *ngIf="
      !!!(eventStateService.isDeletePlantationLoading$ | async);
      else deleteInProgress
    "
  >
    <span class="material-symbols-outlined delete-icon mb-4"> delete </span>
    <span class="header mb-4">
      {{ 'DASHBOARD.DELETE_PLANTATION_CONFIRMATION_MODAL.DELETE' | transloco }}
      {{ plantationName }}?</span
    >
    <span class="message text-center mb-4">
      {{
        'DASHBOARD.DELETE_PLANTATION_CONFIRMATION_MODAL.DELETE_CONFIRMATION_MESSAGE'
          | transloco
      }}
    </span>
    <div class="flex">
      <p-button styleClass="p-button mr-3 delete-button" (onClick)="onDelete()">
        {{
          'DASHBOARD.DELETE_PLANTATION_CONFIRMATION_MODAL.DELETE' | transloco
        }}
      </p-button>
      <p-button styleClass="p-button-outlined" (onClick)="onCancel()">
        {{
          'DASHBOARD.DELETE_PLANTATION_CONFIRMATION_MODAL.CANCEL' | transloco
        }}</p-button
      >
    </div>
  </ng-container>
</div>

<ng-template #deleteInProgress>
  <app-progress-spinner></app-progress-spinner>
</ng-template>
