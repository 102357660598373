import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { TranslocoPipe } from '@jsverse/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddFavoriteFilterPayload } from 'src/app/models/add-favorite-filter-payload.model';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { EventStateService } from 'src/app/services/state-service/event-state.service';

@Component({
  selector: 'app-add-favorite-filter-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, PrimengExportsModule, TranslocoPipe],
  templateUrl: './add-favorite-filter-modal.component.html',
  styleUrls: ['./add-favorite-filter-modal.component.scss']
})
export class AddFavoriteFilterModalComponent {
  filterName = '';
  isLoading = false;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dashboardService: DashboardService,
    private crdStateService: CrdStateService,
    private eventStateService: EventStateService
  ) {}

  onCancel() {
    this.dialogRef.close();
  }

  onDone() {
    this.isLoading = true;
    const payload: AddFavoriteFilterPayload = {
      filter_name: this.filterName,
      filters: this.dialogConfig.data,
      dataset: this.crdStateService.crd
    };

    this.dashboardService.addFavoriteFilter(payload).subscribe({
      next: () => {
        this.eventStateService.refreshTable = true;
        this.dialogRef.close();
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }
}
