<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="flex mb-5">
  <i (click)="onBackClicked()" class="back-button pi pi-chevron-left"></i>
  <p-tabView
    [activeIndex]="activeIndex"
    (activeIndexChange)="onTabChanged($event)"
  >
    <p-tabPanel header="{{ 'DASHBOARD.UPLOAD_MODAL.IMPORT' | transloco }}">
      <app-import-tab
        (isFileInvalid)="isFileInvalid($event)"
        (nextClicked)="nextTabClicked()"
      />
    </p-tabPanel>
    <p-tabPanel
      header="{{ 'DASHBOARD.UPLOAD_MODAL.VERIFY' | transloco }}"
      [disabled]="isMatchTabDisabled"
    >
      <ng-template pTemplate="content">
        <app-match-tab />
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
