<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="flex justify-content-between">
  <div class="flex">
    <span
      class="material-symbols-outlined text-4xl mr-2 back-button"
      (click)="onBackClicked()"
    >
      arrow_back
    </span>
    <div style="margin-top: 0.15rem">
      <app-page-header></app-page-header>
    </div>
  </div>
  <div class="flex justify-content-end mb-3">
    <!-- <app-refresh-button [lastUpdatedDate]="'30/06/2023'"></app-refresh-button> -->
    <div class="flex">
      <!-- <app-request-audit-button class="mr-2"></app-request-audit-button> -->
      <p-button [disabled]="true" styleClass="m-1">
        <i class="pi pi-download"></i
      ></p-button>
    </div>
  </div>
</div>
<!-- <div class="grid">
  <div class="col-12 flex justify-content-end mb-3">
    <div class="flex">
      <app-request-audit-button class="mr-2"></app-request-audit-button>
      <p-button styleClass="m-1"> <i class="pi pi-download"></i></p-button>
    </div>
  </div>
</div> -->

<div class="grid top-section">
  <div class="col-8">
    <p-card class="map-card"><app-map></app-map> </p-card>
  </div>
  <div class="col-4">
    <app-selected-plantations-panel></app-selected-plantations-panel>
  </div>
</div>
<div class="grid mt-1">
  <div class="col-12">
    <app-risk-overlap-panel></app-risk-overlap-panel>
  </div>
</div>

<ng-template #polygonsLoading>
  <div class="progress-bar-skeleton">
    <p-progressBar mode="indeterminate"></p-progressBar>
    <p-skeleton width="15rem" height="7rem"> </p-skeleton>
  </div>
</ng-template>
