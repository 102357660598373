import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  Plantation,
  PlantationTableFilters
} from 'src/app/models/crd-state.interface';

@Injectable({
  providedIn: 'root'
})
export class EventStateService {
  private _zoomPlantation$ = new Subject<Plantation>();
  private _isRiskLoading$ = new BehaviorSubject<boolean>(true);
  private _downloadPlantationInformationLoading$ = new BehaviorSubject<boolean>(
    false
  );
  private _downloadEuIsDataLoading$ = new BehaviorSubject<boolean>(false);
  private _downloadFullRiskReportLoading$ = new BehaviorSubject<boolean>(false);
  private _datasetChanged$ = new Subject<boolean>();
  private _matchColumnsLoading$ = new BehaviorSubject<boolean>(false);
  private _validationLoading$ = new BehaviorSubject<boolean>(false);
  private _isUploadEditInProgress$ = new BehaviorSubject<boolean>(false);
  private _closeUploadModal$ = new BehaviorSubject<boolean>(false);
  private _isImportInProgress$ = new BehaviorSubject<boolean>(false);
  private _hasError$ = new BehaviorSubject<boolean>(false);
  private _isDeletePlantationLoading$ = new BehaviorSubject<boolean>(false);
  // plantation and questionnaire table loader
  private _isDashboardTableLoading$ = new BehaviorSubject<boolean>(false);
  private _refreshTable$ = new Subject<boolean>();
  private _plantationFiltersPayload$ =
    new BehaviorSubject<PlantationTableFilters | null>(null);
  private _isTotalSummaryLoading$ = new BehaviorSubject<boolean>(true);
  private _isStatisticsUpdating$ = new BehaviorSubject<boolean>(false);
  private _isPlantationFilterLoading$ = new BehaviorSubject<boolean>(false);
  private _downloadMappingDataQualityReportLoading$ =
    new BehaviorSubject<boolean>(false);
  // used in si linking summary page
  private _isGeometryDataLoading$ = new BehaviorSubject<boolean>(false);

  get zoomPlantation$() {
    return this._zoomPlantation$.asObservable();
  }

  set zoomPlantation(plantation: Plantation) {
    this._zoomPlantation$.next(plantation);
  }

  get plantationFiltersPayload$() {
    return this._plantationFiltersPayload$.asObservable();
  }

  set plantationFiltersPayload(filters: any) {
    this._plantationFiltersPayload$.next(filters);
  }

  get isRiskLoading$() {
    return this._isRiskLoading$.asObservable();
  }

  set isRiskLoading(isRiskLoading: boolean) {
    this._isRiskLoading$.next(isRiskLoading);
  }

  get isPlantationFilterLoading$() {
    return this._isPlantationFilterLoading$.asObservable();
  }

  set isPlantationFilterLoading(isPlantationFilterLoading: boolean) {
    this._isPlantationFilterLoading$.next(isPlantationFilterLoading);
  }

  get isStatisticsUpdating$() {
    return this._isStatisticsUpdating$.asObservable();
  }

  set isStatisticsUpdating(isStatisticsUpdating: boolean) {
    this._isStatisticsUpdating$.next(isStatisticsUpdating);
  }

  get matchColumnsLoading$() {
    return this._matchColumnsLoading$.asObservable();
  }

  set matchColumnsLoading(matchColumnsLoading: boolean) {
    this._matchColumnsLoading$.next(matchColumnsLoading);
  }

  get validationLoading$() {
    return this._validationLoading$.asObservable();
  }

  set validationLoading(validationLoading: boolean) {
    this._validationLoading$.next(validationLoading);
  }

  get closeUploadModal$() {
    return this._closeUploadModal$.asObservable();
  }

  set closeUploadModal(closeUploadModal: boolean) {
    this._closeUploadModal$.next(closeUploadModal);
  }

  get isImportInProgress$() {
    return this._isImportInProgress$.asObservable();
  }

  set isImportInProgress(isImportInProgress: boolean) {
    this._isImportInProgress$.next(isImportInProgress);
  }

  get isUploadEditInProgress$() {
    return this._isUploadEditInProgress$.asObservable();
  }

  set isUploadEditInProgress(isUploadEditInProgress: boolean) {
    this._isUploadEditInProgress$.next(isUploadEditInProgress);
  }

  get downloadPlantationInformationLoading$() {
    return this._downloadPlantationInformationLoading$.asObservable();
  }

  set downloadPlantationInformationLoading(
    downloadPlantationInformationLoading: boolean
  ) {
    this._downloadPlantationInformationLoading$.next(
      downloadPlantationInformationLoading
    );
  }

  get downloadEuIsDataLoading$() {
    return this._downloadEuIsDataLoading$.asObservable();
  }

  set downloadEuIsDataLoading(downloadEuIsDataLoading: boolean) {
    this._downloadEuIsDataLoading$.next(downloadEuIsDataLoading);
  }

  get downloadFullRiskReportLoading$() {
    return this._downloadFullRiskReportLoading$.asObservable();
  }

  set downloadFullRiskReportLoading(downloadFullRiskReportLoading: boolean) {
    this._downloadFullRiskReportLoading$.next(downloadFullRiskReportLoading);
  }

  get downloadMappingDataQualityReportLoading$() {
    return this._downloadMappingDataQualityReportLoading$.asObservable();
  }

  set downloadMappingDataQualityReportLoading(
    downloadMappingDataQualityReportLoading: boolean
  ) {
    this._downloadMappingDataQualityReportLoading$.next(
      downloadMappingDataQualityReportLoading
    );
  }

  get datasetChanged$() {
    return this._datasetChanged$.asObservable();
  }

  set datasetChanged(datasetChanged: boolean) {
    this._datasetChanged$.next(datasetChanged);
  }

  get hasError$() {
    return this._hasError$.asObservable();
  }

  set hasError(hasError: boolean) {
    this._hasError$.next(hasError);
  }

  get isDeletePlantationLoading$() {
    return this._isDeletePlantationLoading$.asObservable();
  }

  set isDeletePlantationLoading(isDeletePlantationLoading: boolean) {
    this._isDeletePlantationLoading$.next(isDeletePlantationLoading);
  }

  get isDashboardTableLoading$() {
    return this._isDashboardTableLoading$.asObservable();
  }

  set isDashboardTableLoading(isDashboardTableLoading: boolean) {
    this._isDashboardTableLoading$.next(isDashboardTableLoading);
  }

get refreshTable$() {
    return this._refreshTable$.asObservable();
  }

  set refreshTable(refreshTable: boolean) {
    this._refreshTable$.next(refreshTable);
  }

  get isTotalSummaryLoading$() {
    return this._isTotalSummaryLoading$.asObservable();
  }

  set isTotalSummaryLoading(isTotalSummaryLoading: boolean) {
    this._isTotalSummaryLoading$.next(isTotalSummaryLoading);
  }

  get isGeometryDataLoading$() {
    return this._isGeometryDataLoading$.asObservable();
  }

  set isGeometryDataLoading(isGeometryDataLoading: boolean) {
    this._isGeometryDataLoading$.next(isGeometryDataLoading);
  }

  resetAllLoadingStates() {
    this.isRiskLoading = false;
    this.downloadEuIsDataLoading = false;
    this.downloadPlantationInformationLoading = false;
    this.downloadMappingDataQualityReportLoading = false;
    this.validationLoading = false;
    this.downloadFullRiskReportLoading = false;
    this.isDeletePlantationLoading = false;
    this.isDashboardTableLoading = false;
    this.isTotalSummaryLoading = false;
    this.isStatisticsUpdating = false;
    this.isGeometryDataLoading = false;
  }
}
