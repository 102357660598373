<div #container>
  <ng-container
    *ngIf="
      (crdStateService.datasets$ | async) !== undefined || hasRouteParams;
      else datasetLoading
    "
  >
    <ng-container
      *ngIf="
        hasRouteParams || (crdStateService.datasets$ | async)!.length > 0;
        else emptyDataset
      "
    >
      <ng-container [ngSwitch]="crdStateService.currentScreen$ | async">
        <ng-container *ngSwitchDefault>
          <app-summary-dashboard></app-summary-dashboard>
        </ng-container>

        <ng-container *ngSwitchCase="screens.MAP_PAGE">
          <app-map-page />
        </ng-container>

        <ng-container *ngSwitchCase="screens.SETTINGS">
          <app-settings-page />
        </ng-container>

        <ng-container *ngSwitchCase="screens.SI_LINKING">
          <app-si-linking-page />
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #emptyDataset>
  <div
    class="flex align-items-center justify-content-center"
    style="height: 100%"
  >
    <span class="no-dataset-found"
      ><b
        >No data available. Please contact Agridence administrator for more
        information.</b
      ></span
    >
  </div>
</ng-template>

<ng-template #datasetLoading>
  <div
    class="flex align-items-center justify-content-center"
    style="height: 90vh"
  >
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>
