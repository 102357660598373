import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from '../services/auth.service';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { CrdStateService } from '../services/state-service/crd-state.service';
import { EventStateService } from '../services/state-service/event-state.service';

@Injectable()
export class CrdInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private crdStateService: CrdStateService,
    private eventStateService: EventStateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.eventStateService.hasError = false;
    const authToken = `Token ${this.authService.userInfoToken}`;

    const params = request.params
      .set('crd', this.crdStateService.crd)
      .set('plantation_table', this.crdStateService.plantationTable);
    const modifiedRequest = request.clone({
      setHeaders: {
        Authorization: authToken
      },
      params:
        request.url.includes('v1/dataset') ||
        request.url.includes('plantation-data/validate') ||
        request.url.includes('plantations/?crd') ||
        request.url.includes('file-upload/columns') ||
        request.url.includes('assets') ||
        (request.url.includes('favorites') && request.method === 'POST')
          ? undefined
          : params
    });

    return next.handle(modifiedRequest!).pipe(
      catchError((error: HttpErrorResponse) => {
        this.eventStateService.hasError = true;
        this.eventStateService.resetAllLoadingStates();
        if (
          error.status === 401 ||
          (error.status === 404 && error.message === 'Not found')
        ) {
          this.router.navigate(['error'], {
            queryParams: { status: error.status, detail: error.error.detail }
          });
          sessionStorage.removeItem('ARP_Token');
        } else {
          this.dialogService.open(ErrorPageComponent, {
            data: error
          });
        }

        return throwError(() => error.error.detail);
      })
    );
  }
}
