import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrdStateService } from '../services/state-service/crd-state.service';
import { PrimengExportsModule } from '../primeng-exports.module';
import { SelectedPlantationsPanelComponent } from './selected-plantations-panel/selected-plantations-panel.component';
import { RiskOverlapPanelComponent } from './risk-overlap-panel/risk-overlap-panel.component';
import { MapComponent } from './map/map.component';
import { RefreshButtonComponent } from '../shared/refresh-button/refresh-button.component';
import { RequestAuditButtonComponent } from '../shared/request-audit-button/request-audit-button.component';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { ScreenEnum } from '../enums/screens.enum';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-map-page',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    SelectedPlantationsPanelComponent,
    RiskOverlapPanelComponent,
    MapComponent,
    RefreshButtonComponent,
    RequestAuditButtonComponent,
    PageHeaderComponent
  ],
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss']
})
export class MapPageComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  constructor(
    public crdStateService: CrdStateService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.setSelectedPlantationsGeometry();
    this.crdStateService.getMapStyle();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.clearStates();
  }

  setSelectedPlantationsGeometry() {
    combineLatest([
      this.crdStateService.selectedPlantationList$,
      this.crdStateService.selectedPeriod$
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([plantationList, selectedPeriod]) => {
        if (plantationList && selectedPeriod) {
          this.crdStateService.getGeometryData({
            period: selectedPeriod,
            plantationCodes: this.utilityService.getPlantationCodesArray(
              plantationList,
              'plantation_code'
            )
          });
        }
      });
  }

  onBackClicked() {
    this.crdStateService.setCurrentScreen(ScreenEnum.DASHBOARD);
  }

  clearStates() {
    this.crdStateService.setCheckedSelectedPlantationList([]);
    this.crdStateService.setGeometryData([]);
  }
}
