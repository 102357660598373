<app-page-header />
<div class="grid">
  <div class="col-12 pb-0 text-lg font-semibold flex justify-content-between">
    <span>
      <ng-container *ngIf="(selectedTab$ | async) === PLANTATION_TAB">{{
        'DASHBOARD.SUMMARY' | transloco
      }}</ng-container>
    </span>
    <div class="flex dataset-controls">
      <app-dataset-autocomplete />

      <div
        class="flex align-item-center period-dropdown ml-3 mb-2"
        *ngIf="(selectedTab$ | async) === PLANTATION_TAB"
      >
        <p-calendar
          [ngModel]="selectedPeriod"
          dateFormat="yy-mm-dd"
          [readonlyInput]="true"
          [appendTo]="'body'"
          (ngModelChange)="onSelectedPeriodChanged($event)"
          [showIcon]="true"
        />
      </div>
    </div>
  </div>
</div>
<div class="my-2" *ngIf="(selectedTab$ | async) === PLANTATION_TAB">
  <app-summary-charts />
</div>
<div class="grid">
  <div class="col-12">
    <app-plantation-table />
  </div>
</div>
