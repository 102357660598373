import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Dataset } from 'src/app/models/crd-state.interface';
import { FormsModule } from '@angular/forms';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { combineLatest, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dataset-autocomplete',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule, FormsModule],
  templateUrl: './dataset-autocomplete.component.html',
  styleUrls: ['./dataset-autocomplete.component.scss']
})
export class DatasetAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('datasetAutocomplete') datasetAutocomplete!: AutoComplete;
  @Input() disabled = false;
  @Input() isSiLinkingPage = false;
  filteredDatasetOptions!: Dataset[];
  datasetOptions!: Dataset[];
  selectedDataset!: Dataset;
  previousSelectedDataset!: Dataset;
  destroyed$ = new Subject<void>();

  constructor(private crdStateService: CrdStateService) {}

  ngOnInit(): void {
    this.initializeDatasets();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeDatasets() {
    combineLatest([
      this.crdStateService.datasets$,
      this.crdStateService.selectedDataset$
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([datasets, selectedDataset]) => {
        if (datasets) {
          this.datasetOptions = datasets;
          if (!selectedDataset) {
            this.crdStateService.setSelectedDataset(datasets[0]);
          }
        }
        if (selectedDataset) {
          this.selectedDataset = selectedDataset;
          this.previousSelectedDataset = selectedDataset;
        }
      });
  }

  filterDataset(event: AutoCompleteCompleteEvent) {
    const filtered: any[] = [];
    const query = event.query;

    for (let i = 0; i < this.datasetOptions.length; i++) {
      const dataset = this.datasetOptions[i];
      if (dataset?.description?.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(dataset);
      }
    }

    this.filteredDatasetOptions = filtered;
  }

  onDatasetOptionsBlur(event: Event) {
    const textInput = (event.target as any).value;
    const isValidInput = this.filteredDatasetOptions.some(
      (option) => option.name === textInput
    );
    if (!isValidInput && this.datasetAutocomplete.inputEL) {
      this.datasetAutocomplete.inputEL.nativeElement.value =
        this.previousSelectedDataset.description;
    }
  }

  onSelectedDatasetChanged(dataset: Dataset) {
    if (dataset.name !== this.previousSelectedDataset.name) {
      this.crdStateService.crd = dataset.name;
      this.crdStateService.plantationTable = dataset.default_plantations_table;
      this.crdStateService.resetRiskProperties();
      this.crdStateService.getRiskProperties();
      this.crdStateService.updateDataset(this.isSiLinkingPage);
      this.crdStateService.setSelectedDataset(dataset);
    }
  }
}
