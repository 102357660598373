<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  class="container flex flex-column justify-content-center align-items-center"
>
  <span class="mb-2 text-xl font-semibold">{{
    'DASHBOARD.UPLOAD_MODAL.UPLOAD_A_FILE' | transloco
  }}</span>
  <span class="mb-2">{{
    'DASHBOARD.UPLOAD_MODAL.ACCEPTED_FILE_TYPE_CSV_XLSX' | transloco
  }}</span>
  <span class="mb-3">{{
    'DASHBOARD.UPLOAD_MODAL.MAX_SIZE_10_MB' | transloco
  }}</span>

  <div class="flex w-full justify-content-center">
    <a
      href="https://agridence.github.io/public-docs/traceability/file_uploads/"
      target="_blank"
      >{{ 'DASHBOARD.UPLOAD_MODAL.LEARN_MORE' | transloco }}</a
    >
    <a class="ml-6" (click)="op.toggle($event)">{{
      'DASHBOARD.UPLOAD_MODAL.DOWNLOAD_REFERENCE' | transloco
    }}</a>
  </div>
  <p-overlayPanel #op>
    <div class="flex flex-column download-options">
      <div *ngFor="let template of downloadTemplateOptions">
        <div
          (click)="downloadTemplate(template)"
          class="flex align-items-center download-option"
        >
          <span> {{ template.label }}</span>
        </div>
      </div>
    </div>
  </p-overlayPanel>

  <div class="flex flex-column">
    <div
      appFileDnd
      (fileDropped)="onFileDropped($event)"
      class="file-upload-control flex flex-column align-items-center justify-content-center my-4"
      (click)="onFileSelect()"
    >
      <ng-container *ngIf="!uploadedFile; else hasUploadedFile">
        <p-button class="mb-3"
          ><span class="material-symbols-outlined mr-2"> cloud_upload </span
          >{{ 'DASHBOARD.UPLOAD_MODAL.BROWSE_FILE' | transloco }}</p-button
        >
        <span>{{
          'DASHBOARD.UPLOAD_MODAL.DRAG_AND_DROP_A_FILE_HERE' | transloco
        }}</span>
      </ng-container>
    </div>

    <div class="flex justify-content-end" style="width: 100%">
      <p-button (onClick)="onActionClicked()" [disabled]="!uploadedFile">
        {{ actionLabel | transloco }}</p-button
      >
    </div>
  </div>
</div>
<ng-template #hasUploadedFile>
  <div
    *ngIf="!hasError && isDataParsed"
    class="flex justify-content-between align-items-center px-5 w-full"
  >
    <div class="flex">
      <span class="mr-3" style="max-width: 20rem">
        {{ uploadedFile?.name }}
      </span>
      <span>
        {{ uploadedFile ? (uploadedFile.size | fileSize) : '' }}
      </span>
    </div>
    <p-button (onClick)="onFileDelete($event)" icon="pi pi-times"></p-button>
  </div>
  <div
    *ngIf="hasError && isDataParsed"
    class="error-section flex flex-column align-items-center justify-content-center my-4"
  >
    <span class="material-symbols-outlined text-6xl"> cloud_upload </span>
    <span
      >{{ errorMessage | transloco
      }}{{ errorMessageTemplate | transloco }}</span
    >
  </div>
</ng-template>

<input
  #inputFile
  type="file"
  name="inputFile"
  id="inputFile"
  (click)="$any($event.target).value = null"
  (change)="onFileSelected($event)"
  hidden="true"
  [accept]="formattedAcceptedFileTypes"
/>
