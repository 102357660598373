<div
  *ngIf="!dialogData; else errorModal"
  class="grid align-items-center"
  style="padding: 0 15rem; height: 100vh"
>
  <div class="col-6">
    <div class="grid flex-column text-left">
      <div class="col my-4 error-status">
        <span>{{ errorStatus }}</span>
      </div>
      <div class="col my-4 error-header">
        <span>{{ errorHeader }}</span>
      </div>
      <div class="col my-1 error-detail">
        <span>{{ errorDetail }}</span>
      </div>
    </div>
  </div>
  <div class="col-6">
    <img
      style="height: 50rem"
      src="../../assets/images/error-image.png"
      alt=""
    />
  </div>
</div>

<ng-template #errorModal>
  <div class="flex align-items-center pt-0 px-8">
    <div class="grid flex-column text-center">
      <div class="col">
        <i class="pi pi-times-circle" style="font-size: 5rem; color: red"></i>
      </div>
      <div class="col pb-0">
        <h2>{{ errorHeader | uppercase }}</h2>
      </div>
      <div class="col pt-0">
        <h4>{{ errorDetail }}</h4>
      </div>
      <div class="col">
        <p-button (onClick)="onClick()">{{ buttonLabel }}</p-button>
      </div>
    </div>
  </div>
</ng-template>
