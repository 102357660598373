import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from '../../primeng-exports.module';
import { FormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  map,
  take,
  takeUntil
} from 'rxjs';
import {
  FavoriteFilter,
  Filters,
  INITIAL_PAGED_STATE,
  INITIAL_TABLE_PARAMS,
  Plantation
} from '../../models/crd-state.interface';
import { CrdStateService } from '../../services/state-service/crd-state.service';
import { RequestAuditButtonComponent } from 'src/app/shared/request-audit-button/request-audit-button.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDeleteComponent } from '../../shared/confirm-delete/confirm-delete.component';
import {
  Table,
  TableHeaderCheckboxToggleEvent,
  TableLazyLoadEvent
} from 'primeng/table';
import { UtilityService } from 'src/app/services/utility.service';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { RiskLoaderComponent } from 'src/app/shared/risk-loader/risk-loader.component';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { UploadFileModalComponent } from '../upload-file-modal/upload-file-modal.component';
import { TableState } from 'primeng/api';
import { DownloadOptionsEnum } from 'src/app/enums/download-options.enum';
import { DownloadService } from 'src/app/services/download.service';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import {
  PLANTATION_LIST_TABLE_COLUMNS,
  QUESTIONNAIRE_TABLE_COLUMNS
} from 'src/app/constants/table-columns.const';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { PlantationTableTabsEnum } from 'src/app/enums/plantation-table-tabs.enum';
import { DeletePlantationPayload } from 'src/app/models/delete-plantation-payload.model';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { ProgressSpinnerComponent } from 'src/app/shared/progress-spinner/progress-spinner.component';
import { TableParams } from 'src/app/models/table-params.model';
import { GeoJsonTypesEnum } from 'src/app/enums/geojson-types.enum';
import { AddFavoriteFilterModalComponent } from '../add-favorite-filter-modal/add-favorite-filter-modal.component';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-plantation-table',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    RequestAuditButtonComponent,
    ConfirmDeleteComponent,
    RiskLoaderComponent,
    RiskIconComponent,
    TranslocoPipe,
    ProgressSpinnerComponent
  ],
  templateUrl: './plantation-table.component.html',
  styleUrls: ['./plantation-table.component.scss']
})
export class PlantationTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt!: Table;
  @Input() isSiLinking = false;
  @Output() selectionChanged = new EventEmitter<Plantation[]>();
  rowData: Plantation[] = [];
  clonedRowData: Plantation[] = [];

  selectedPlantations: Plantation[] = [];
  selectedPlantationNames: string[] = [];
  globalFilterFields: string[] = [];

  columns: ColumnDefinition[] = PLANTATION_LIST_TABLE_COLUMNS;
  destroyed$ = new Subject<void>();

  INITIAL_PAGED_STATE = INITIAL_PAGED_STATE;

  riskTypes = RiskTypesEnum;
  selectedItems: Plantation[] = [];
  selectedItemNames: string[] = [];

  tableDataKey = '';

  tableParams: TableParams = INITIAL_TABLE_PARAMS;
  totalCount = 0;
  plantationFilters!: any;
  // use as trigger for payloads
  transformedSelectedFilters$ = new BehaviorSubject<Filters | null>(null);
  globalSearchInput = '';

  downloadOptions = DownloadOptionsEnum;
  deletePlantationPayload!: DeletePlantationPayload;

  favoritePlantationFilters: FavoriteFilter[] = [];
  selectedFavoriteFilter: FavoriteFilter | null = null;

  // transformed filter query
  activePlantationFiltersQuery: Filters = {
    AND: {},
    OR: {}
  };
  // filters used in primeNG table
  // TODO: update types
  activeTableFilters!: any;
  defaultFilters!: any;
  assignedFavoriteFilters: any = {};

  selectedTab!: PlantationTableTabsEnum;
  PLANTATION_TAB = PlantationTableTabsEnum.PLANTATION;
  QUESTIONNAIRE_TAB = PlantationTableTabsEnum.QUESTIONNAIRE;

  get isListControlsDisabled() {
    return this.selectedItems.length <= 0;
  }

  get isSelectedMaxed() {
    return this.selectedItems.length === 10;
  }

  get isPlantationTab() {
    return this.selectedTab === PlantationTableTabsEnum.PLANTATION;
  }

  get tableStyle() {
    return this.isPlantationTab ? { 'min-width': '130rem' } : {};
  }

  get enableAddFavoriteFilter() {
    return (
      this.selectedItems.length > 0 ||
      Object.keys(this.activePlantationFiltersQuery.AND!).length !== 0 ||
      Object.keys(this.activePlantationFiltersQuery.OR!).length !== 0
    );
  }

  downloadOptionProps = [
    {
      label: this.translateDownloadOptionLabel('PLANTATION_INFORMATION_CSV'),
      loadingState:
        this.eventStateService.downloadPlantationInformationLoading$,
      downloadOption: DownloadOptionsEnum.PLANTATION_INFORMATION
    },
    {
      label: this.translateDownloadOptionLabel('RISK_REPORT_CSV'),
      loadingState: this.eventStateService.downloadFullRiskReportLoading$,
      downloadOption: DownloadOptionsEnum.FULL_RISK_REPORT,
      isAlwaysEnabled: true
    },
    {
      label: this.translateDownloadOptionLabel(
        'MAPPING_DATA_QUALITY_REPORT_CSV'
      ),
      loadingState:
        this.eventStateService.downloadMappingDataQualityReportLoading$,
      downloadOption: DownloadOptionsEnum.MAPPING_DATA_QUALITY_REPORT,
      isAlwaysEnabled: true
    }
  ];

  constructor(
    public crdStateService: CrdStateService,
    private dialogService: DialogService,
    public utilityService: UtilityService,
    public eventStateService: EventStateService,
    private downloadService: DownloadService,
    private translocoService: TranslocoService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.listenToDatasetChange();
    this.setSelectedTab(PlantationTableTabsEnum.PLANTATION);
    this.initializePaginatedPlantations();
    this.initializePaginatedQuestionnaires();
    this.initializeDeletePlantationParams();
    this.getFavoritePlantationFilters();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getFavoritePlantationFilters() {
    this.crdStateService.getFavoritePlantationFilters();
  }

  listenToDatasetChange() {
    this.eventStateService.datasetChanged$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.setSelectedTab(PlantationTableTabsEnum.PLANTATION);
        this.onClearAllClicked();
        this.globalSearchInput = '';
        sessionStorage.removeItem('dashboardTableState');
        this.dt.reset();
      });

    this.eventStateService.refreshTable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getFavoritePlantationFilters();
        this.onClearAllClicked();
        this.eventStateService.isDashboardTableLoading = true;
        this.dt.ngOnInit();
      });
  }

  initializePaginatedPlantations() {
    this.crdStateService.getPlantationListFilters();
    this.globalFilterFields = this.columns.map((c) => c.field);
    combineLatest([
      this.crdStateService.pagedPlantationList$,
      this.crdStateService.selectedPlantationList$
    ])
      .pipe(
        takeUntil(this.destroyed$),
        filter((_) => !!this.isPlantationTab),
        filter(
          ([pagedPlantationList, _]) =>
            pagedPlantationList !== INITIAL_PAGED_STATE
        ),
        map(([pagedPlantationList, selectedPlantations]) => {
          const rowData = pagedPlantationList.results.map((row) => ({
            ...row,
            checked: false
          }));
          const totalCount = pagedPlantationList.count;
          let selectedPlantationNames: string[] = [];
          if (selectedPlantations) {
            const selectedPlantationMap = new Map(
              selectedPlantations.map((plantation) => [
                plantation.plantation_code,
                plantation
              ])
            );

            selectedPlantationNames = selectedPlantations.map(
              (plantation) => plantation.plantation_name
            );

            rowData.forEach((row) => {
              if (selectedPlantationMap.has(row.plantation_code)) {
                row.checked = true;
              }
            });
          }

          return {
            rowData,
            totalCount,
            selectedPlantations,
            selectedPlantationNames
          };
        })
      )
      .subscribe(
        ({
          rowData,
          totalCount,
          selectedPlantations,
          selectedPlantationNames
        }) => {
          this.rowData = rowData;
          this.totalCount = totalCount;
          this.selectedItems = selectedPlantations;
          this.selectedItemNames = selectedPlantationNames;
          this.tableDataKey = 'plantation_code';
        }
      );

    this.crdStateService.plantationListFilters$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filters) => {
        if (filters) {
          this.plantationFilters = filters;
          this.defaultFilters = this.constructDefaultFilters(
            this.plantationFilters
          );
          const filterKeys = Object.keys(this.plantationFilters);

          this.columns.forEach((column) => {
            column.filter = filterKeys.includes(column.field);
          });
        }
      });
  }

  constructDefaultFilters(input: any) {
    const output: any = {};
    Object.keys(input).forEach((key) => {
      output[key] = [
        {
          value: null,
          matchMode: 'startsWith',
          operator: 'and'
        }
      ];
    });

    output.global = { value: '' };
    return output;
  }

  initializePaginatedQuestionnaires() {
    this.globalFilterFields = this.columns.map((c) => c.field);

    combineLatest([
      this.crdStateService.pagedQuestionnaireList$,
      this.crdStateService.selectedQuestionnaireList$
    ])
      .pipe(
        takeUntil(this.destroyed$),
        filter((_) => !this.isPlantationTab),
        filter(
          ([pagedPlantationList, _]) =>
            pagedPlantationList !== INITIAL_PAGED_STATE
        ),
        map(([pagedPlantationList, selectedPlantations]) => {
          const rowData = pagedPlantationList.results.map(
            (row: Plantation) => ({
              ...row,
              checked: false
            })
          );
          const totalCount = pagedPlantationList.count;
          let selectedPlantationNames: string[] = [];

          if (selectedPlantations) {
            const selectedPlantationMap = new Map(
              selectedPlantations.map((plantation) => [
                plantation.plantation_code,
                plantation
              ])
            );

            selectedPlantationNames = selectedPlantations.map(
              (plantation) => plantation.plantation_name
            );

            rowData.forEach((row: Plantation) => {
              if (selectedPlantationMap.has(row.plantation_code)) {
                row.checked = true;
              }
            });
          }

          return {
            rowData,
            totalCount,
            selectedPlantations,
            selectedPlantationNames
          };
        })
      )
      .subscribe(
        ({
          rowData,
          totalCount,
          selectedPlantations,
          selectedPlantationNames
        }) => {
          this.rowData = rowData;
          this.totalCount = totalCount;
          this.selectedItems = selectedPlantations;
          this.selectedItemNames = selectedPlantationNames;
          this.tableDataKey = '_id';
        }
      );

    this.crdStateService.plantationListFilters$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filters) => {
        if (filters) {
          this.plantationFilters = filters;
          const filterKeys = Object.keys(this.plantationFilters);

          this.columns.forEach((column) => {
            column.filter = filterKeys.includes(column.field);
          });
        }
      });
  }

  lazyLoadData(event: TableLazyLoadEvent) {
    this.eventStateService.isDashboardTableLoading = true;
    if (this.selectedItems.length) {
      if (this.isPlantationTab) {
        this.crdStateService.setSelectedPlantationList(this.selectedItems);
      } else {
        this.crdStateService.setSelectedQuestionnaireList(this.selectedItems);
      }
    }
    this.eventStateService.isDashboardTableLoading = true;
    let transformedSelectedFilters = this.convertToFilterPayload(event.filters);
    const pageNumber = event?.first ?? 1;
    const pageSize = event?.rows ?? 10;
    const sortOrder = event.sortOrder === -1 ? '-' : '';
    const sort = event?.sortField
      ? `${sortOrder}${event?.sortField ?? ''}`
      : '';
    const globalFilter = event.globalFilter;
    if (globalFilter) {
      transformedSelectedFilters = {
        ...transformedSelectedFilters
      };
    }
    this.transformedSelectedFilters$.next(transformedSelectedFilters);
    this.activePlantationFiltersQuery = { ...transformedSelectedFilters };

    this.tableParams = {
      page: Math.floor((pageNumber + pageSize) / pageSize),
      pageSize: pageSize,
      filters: transformedSelectedFilters,
      orderBy: sort
    };

    if (this.isPlantationTab) {
      this.crdStateService.getPlantationsPaginated(this.tableParams);
    } else {
      this.crdStateService.getQuestionnairesPaginated({
        ...this.tableParams,
        crd: ['']
      });
    }
    this.activeTableFilters = event.filters;
  }

  onTableStateRestore(event: TableState) {
    const globalSearchInput = (event?.filters as any)?.global?.value;
    if (globalSearchInput) {
      this.globalSearchInput = globalSearchInput;
    }
  }

  onFilterChange() {
    this.subscribePlantationFilterChange();
  }

  convertToFilterPayload(config: any): any {
    const transformedConfig: any = {
      AND: {},
      OR: {}
    };

    Object.keys(config).forEach((key) => {
      if (key === 'global') {
        if (config?.global?.value) {
          transformedConfig['AND']['plantation_name'] = config?.global?.value;
        }
        return;
      }

      const valueArray = config[key]
        .map((item: any) => item.value)
        .flat()
        .filter((value: any) => value !== null);

      if (valueArray.length > 0) {
        if (key === 'risks') {
          valueArray.forEach((value: any, index: number) => {
            Object.keys(value).forEach((riskKey) => {
              transformedConfig['OR'][`ifnull(${riskKey}, false)`] = [
                valueArray[index][riskKey]
              ];
            });
          });

          return;
          // special case - plantation name filters is actually geo types filters
        } else if (key === 'plantation_name') {
          transformedConfig['AND']["ifnull(_geo_type, 'Invalid Geometry')"] =
            valueArray.map((value: string) =>
              value === '' ? 'Invalid Geometry' : value
            );
        } else {
          transformedConfig['AND'][key] = valueArray;
        }
      }
    });

    return transformedConfig;
  }

  initializeDeletePlantationParams() {
    this.crdStateService.selectedDataset$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedDataset) => {
        if (selectedDataset) {
          this.deletePlantationPayload = {
            dataset: selectedDataset.name,
            plantation_table: selectedDataset.default_plantations_table,
            plantation_code: ''
          };
        }
      });
  }

  getFilterOptions(columnField: string) {
    return this.plantationFilters[columnField];
  }

  onHeaderCheckboxToggle(event: TableHeaderCheckboxToggleEvent) {
    if (!event.checked) {
      this.onClearAllClicked();
    }
  }

  onFavoriteFilterSelectionChange(event: DropdownChangeEvent) {
    this.globalSearchInput = '';
    // TODO: temp workaround only for clearing back to default filters
    if (
      event.value?.filter_name.toLowerCase() === 'no filter' &&
      event.value?.id === 999
    ) {
      this.selectedFavoriteFilter = null;
    }
    const value = event.value?.filters as Filters;
    this.assignSelectedFilters(value);
    if (value?.AND && (value.AND as any)['plantation_code']?.length) {
      this.assignedFavoriteFilters.plantation_code = [
        {
          value: (value.AND as any)['plantation_code'],
          matchMode: 'startsWith',
          operator: 'and'
        }
      ];
    }

    if (value) {
      this.dt.filters = structuredClone(this.assignedFavoriteFilters);
    } else {
      this.dt.filters = structuredClone(this.defaultFilters);
    }
    this.dt._filter();
  }

  assignSelectedFilters(filters: Filters) {
    this.assignedFavoriteFilters = structuredClone(this.defaultFilters);

    const assignValues = (filterGroup: Record<string, any>) => {
      Object.keys(filterGroup).forEach((key) => {
        const normalizedKey = key.includes('_geo_type')
          ? 'plantation_name'
          : key.includes('risk')
          ? 'risks'
          : key.includes('plantation_name')
          ? 'global'
          : key;

        let riskKey = '';

        if (key.includes('risk')) {
          const regex = /^ifnull\(([^,]+),/;
          const match = key.match(regex);
          riskKey = match ? match[1] : '';
        }

        if (this.assignedFavoriteFilters[normalizedKey]) {
          let value = filterGroup[key];

          if (normalizedKey === 'risks') {
            value = { [riskKey]: value[0] };
          }

          const target =
            normalizedKey === 'global'
              ? this.assignedFavoriteFilters[normalizedKey]
              : this.assignedFavoriteFilters[normalizedKey][0];

          target.value = value;
        }
      });
    };

    if (filters?.AND && Object.keys(filters.AND).length > 0) {
      assignValues(filters.AND);
    }

    if (filters?.OR && Object.keys(filters.OR).length > 0) {
      assignValues(filters.OR);
    }
  }

  onAddFavoriteFilterClicked() {
    const plantationCodeQuery = { AND: {} };
    if (this.selectedItems.length) {
      plantationCodeQuery.AND = {
        plantation_code: this.selectedItems.map(
          (item) => item.plantation_code
        ) as any
      };
    }

    this.dialogService.open(AddFavoriteFilterModalComponent, {
      width: '45%',
      height: '25rem',
      contentStyle: { padding: 0 },
      styleClass: 'add-favorite-filter-modal',
      closeOnEscape: true,
      data: this.selectedItems.length
        ? plantationCodeQuery
        : this.activePlantationFiltersQuery,
      header: this.translocoService.translate('DASHBOARD.ADD_FAVORITE_FILTER')
    });
  }

  onFilterControlClicked() {
    if (!this.isSiLinking) {
      this.selectedFavoriteFilter = null;
    }
  }

  onSelectionChange(plantations: Plantation[]) {
    const selectedNames = plantations.map(
      (plantation) => plantation.plantation_name
    );
    const selectedIds = plantations.map((plantation) =>
      plantation.plantation_code ? plantation.plantation_code : plantation._id
    );

    if (selectedIds.length > 0 && !this.isSiLinking) {
      this.selectedFavoriteFilter = null;
    }

    this.selectedItemNames = selectedNames;

    this.rowData.forEach((row) => {
      if (
        selectedIds.includes(
          row.plantation_code ? row.plantation_code : row._id
        )
      ) {
        row.checked = true;
        return;
      }

      row.checked = false;
    });

    this.selectionChanged.emit(plantations);
  }

  onClearAllClicked() {
    this.selectedPlantations = [];
    this.selectedPlantationNames = [];
    this.crdStateService.setSelectedPlantationList([]);
  }

  onSelectedItemRemove(plantationName: string) {
    const deletedIndex = this.selectedItems.findIndex(
      (p) => p.plantation_name === plantationName
    );

    const tempSelectedPlantations = [...this.selectedItems];
    tempSelectedPlantations.splice(deletedIndex, 1);

    this.selectedItems = [...tempSelectedPlantations];
  }

  onShowMap() {
    this.crdStateService.setSelectedPlantationList(this.selectedItems);
    this.crdStateService.setCurrentScreen(ScreenEnum.MAP_PAGE);
  }

  onDelete(rowData: Plantation) {
    const modalActionsEvent = new EventEmitter<'delete' | 'cancel'>();
    const confirmDeleteModal = this.dialogService.open(ConfirmDeleteComponent, {
      data: {
        modalActions: modalActionsEvent,
        plantationName: rowData.plantation_name
      },
      width: '502px'
    });

    this.deletePlantationPayload.plantation_code = rowData.plantation_code;

    modalActionsEvent.pipe(take(1)).subscribe((action) => {
      switch (action) {
        case 'delete':
          this.eventStateService.isDeletePlantationLoading = true;
          this.dashboardService.deletePlantation(
            this.deletePlantationPayload,
            confirmDeleteModal
          );
          break;
        case 'cancel':
          confirmDeleteModal.close();
          break;
        default:
          break;
      }
    });
  }

  onRowEditInit(rowData: Plantation, index: number) {
    this.clonedRowData[index] = { ...rowData };
    console.log('on row edit init', rowData);
  }

  onRowEditSave(rowData: Plantation) {
    console.log('on row edit save', rowData);
  }

  onRowEditCancel(rowData: Plantation, index: number) {
    console.log('on row edit cancel', rowData, index);
    this.rowData[index] = this.clonedRowData[index];
    delete this.clonedRowData[index];
  }

  onDownloadClicked(option: DownloadOptionsEnum) {
    const plantationCodes = [
      ...this.selectedItems.map((plantation) => plantation.plantation_code)
    ];

    const downloadParams = {
      filters: this.tableParams.filters,
      orderBy: this.tableParams.orderBy,
      plantationCodes: plantationCodes
    };
    switch (option) {
      case DownloadOptionsEnum.PLANTATION_INFORMATION:
        this.downloadService.dynamicDownload(
          downloadParams,
          DownloadOptionsEnum.PLANTATION_INFORMATION
        );

        break;
      case DownloadOptionsEnum.FULL_RISK_REPORT:
        this.downloadService.dynamicDownload(
          downloadParams,
          DownloadOptionsEnum.FULL_RISK_REPORT
        );
        break;
      case DownloadOptionsEnum.MAPPING_DATA_QUALITY_REPORT:
        this.downloadService.dynamicDownload(
          downloadParams,
          DownloadOptionsEnum.MAPPING_DATA_QUALITY_REPORT
        );
        break;
      default:
        return;
    }
  }

  setSelectedTab(selectedTab: PlantationTableTabsEnum) {
    this.crdStateService.setSelectedTab(selectedTab);
    this.selectedTab = selectedTab;
  }

  onTabChange(index: number) {
    this.rowData = [];
    this.eventStateService.isDashboardTableLoading = true;
    if (index === 0) {
      this.setSelectedTab(PlantationTableTabsEnum.PLANTATION);
      this.columns = PLANTATION_LIST_TABLE_COLUMNS;
      this.tableDataKey = 'plantation_code';
    } else {
      this.setSelectedTab(PlantationTableTabsEnum.QUESTIONNAIRE);
      this.columns = QUESTIONNAIRE_TABLE_COLUMNS;
      this.tableDataKey = '_id';
    }

    this.totalCount = 0;
    this.dt.ngOnInit();

    this.globalFilterFields = this.columns.map((c) => c.field);
  }

  applyFilterGlobal(event: Event, filterType: string) {
    this.dt.filterGlobal((event.target as HTMLInputElement).value, filterType);
  }

  onUploadClicked() {
    const bgColor = this.utilityService.getComputedStyle('--body-background');
    const modal = this.dialogService.open(UploadFileModalComponent, {
      width: '80%',
      height: '70rem',
      contentStyle: { 'background-color': bgColor, padding: '2rem 3rem' },
      styleClass: 'upload-file-modal',
      closable: false,
      closeOnEscape: true
    });

    this.eventStateService.closeUploadModal$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isClose) => {
        if (isClose) {
          modal.close();
        }
      });
  }

  translateDownloadOptionLabel(label: string) {
    return this.translocoService.translate(`DASHBOARD.${label}`);
  }

  getGeoJsonIconProps(geoJsonType: GeoJsonTypesEnum) {
    return this.utilityService.getGeoJsonTypeProps(geoJsonType);
  }

  subscribePlantationFilterChange() {
    this.transformedSelectedFilters$.pipe(take(1)).subscribe((filters) => {
      if (filters) {
        this.eventStateService.plantationFiltersPayload = filters;
        this.eventStateService.isStatisticsUpdating = true;
      }
    });
  }
}
