<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="flex flex-column align-items-center match-tab">
  <span class="text-xl font-semibold mb-3 labels">{{
    'DASHBOARD.UPLOAD_MODAL.MATCH_COLUMNS_TO_YOUR_PLANTATION_INFORMATION'
      | transloco
  }}</span>
  <span class="text-sm font-semibold mb-4 labels"
    >{{ 'DASHBOARD.UPLOAD_MODAL.SUB_NOTE' | transloco }}
  </span>

  <div class="mb-3" style="width: 100%">
    <p-card
      *ngIf="
        !(eventStateService.matchColumnsLoading$ | async);
        else tableLoading
      "
    >
      <p-table
        #dt
        [value]="rowData"
        [paginator]="true"
        [rows]="10"
        [columns]="columns"
        editMode="row"
        [totalRecords]="rowData.length"
        [dataKey]="'id'"
        [showCurrentPageReport]="true"
        [loading]="
          (eventStateService.validationLoading$ | async)! ||
          (eventStateService.isImportInProgress$ | async)!
        "
        [tableStyle]="tableWidthStyle"
        currentPageReportTemplate="{{
          'DASHBOARD.SHOWING_TABLE_ENTRIES_COUNT'
            | transloco
              : {
                  first: '{first}',
                  last: '{last}',
                  totalRecords: '{totalRecords}'
                }
        }}"
        [rowsPerPageOptions]="[10, 30, 100]"
      >
        <ng-template pTemplate="caption">
          <div class="grid p-2 mt-0">
            <div class="col-12 flex align-items-center justify-content-between">
              <div class="flex table-header">
                <span class="text-lg mr-4">
                  <span class="text-lg" style="color: var(--primary-color)">
                    {{ rowData.length }}
                  </span>
                  {{
                    'DASHBOARD.UPLOAD_MODAL.NEW_PLANTATIONS_ADDED' | transloco
                  }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <ng-container *ngFor="let column of filteredColumns; index as i">
              <th
                [style]="column.style"
                [pSortableColumn]="column.sortable ? column.field : ''"
              >
                <div class="th-container">
                  <label>
                    {{ column.displayName | transloco }}
                  </label>

                  <p-sortIcon
                    *ngIf="column.sortable"
                    [field]="column.field"
                  ></p-sortIcon>
                </div>
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-editing="editing"
          let-index="rowIndex"
        >
          <tr [pEditableRow]="rowData">
            <ng-container *ngFor="let column of filteredColumns; index as i">
              <td
                *ngIf="column.field !== 'actions'"
                style="word-break: break-word"
              >
                <ng-container
                  *ngIf="!column?.editable && column.field !== 'error'"
                >
                  <label>{{ (rowData[column.field] | truncate) || '-' }}</label>
                </ng-container>
                <ng-container *ngIf="column.field === 'error'">
                  <label
                    class="flex"
                    *ngFor="
                      let errorDescription of rowData['error']?.description
                    "
                    >{{ errorDescription || '-' }}</label
                  >
                </ng-container>
                <ng-container *ngIf="column?.editable">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <!-- *ngIf="column.field !== 'date_created'; else calendar" -->
                      <!-- [style]="column.field === '_geo' ? 'width: 20rem;' : ''" -->
                      <div class="flex align-items-center">
                        <ng-container
                          *ngTemplateOutlet="
                            errorIcon;
                            context: {
                              errorType: rowData['error']?.type,
                              field: column.field
                            }
                          "
                        ></ng-container>
                        <input
                          *ngIf="
                            column.field !== 'geometry';
                            else geometryTextArea
                          "
                          pInputText
                          [type]="
                            column.field === 'land_area' ? 'number' : 'text'
                          "
                          [(ngModel)]="rowData[column.field]"
                        />
                      </div>
                      <ng-template #geometryTextArea>
                        <textarea
                          class="w-full h-6rem"
                          rows="5"
                          cols="30"
                          pInputTextarea
                          [(ngModel)]="rowData[column.field]"
                        ></textarea>
                      </ng-template>
                      <!-- <ng-template #calendar>
                        <p-calendar
                          dateFormat="dd/mm/yy"
                          [(ngModel)]="rowData[column.field]"
                        ></p-calendar>
                      </ng-template> -->
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div class="flex align-items-center">
                        <!-- *ngIf="column.field !== 'date_created'; else date" -->
                        <ng-container
                          *ngTemplateOutlet="
                            errorIcon;
                            context: {
                              errorType: rowData['error']?.type,
                              field: column.field
                            }
                          "
                        ></ng-container>
                        <label
                          >{{ (rowData[column.field] | truncate) || '-' }}
                        </label>
                        <!-- <ng-template #date
                          ><label>
                            {{ getFormattedDate(rowData[column.field]) }}
                          </label></ng-template
                        > -->
                      </div>
                    </ng-template>
                  </p-cellEditor>
                </ng-container>
              </td>
            </ng-container>
            <td class="action-cell">
              <!-- TODO: update to actual data source value provided on API integration -->
              <ng-container>
                <button
                  *ngIf="!editing"
                  pButton
                  pRipple
                  type="button"
                  pInitEditableRow
                  (click)="onRowEditInit(rowData, index)"
                  class="p-button-text table-action"
                >
                  <span class="material-symbols-outlined edit-icon">
                    edit
                  </span>
                </button>
                <button
                  *ngIf="!editing"
                  pButton
                  pRipple
                  type="button"
                  class="p-button-text table-action"
                >
                  <span
                    class="material-symbols-outlined delete-icon"
                    (click)="onDelete(rowData, index)"
                  >
                    delete
                  </span>
                </button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pSaveEditableRow
                  (click)="onRowEditSave(rowData, index)"
                  class="p-button-text table-action p-button-success"
                >
                  <span class="material-symbols-outlined"> done </span>
                </button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pCancelEditableRow
                  (click)="onRowEditCancel(index)"
                  class="p-button-text table-action p-button-danger"
                >
                  <span class="material-symbols-outlined"> close </span>
                </button>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>

  <div
    *ngIf="!(eventStateService.matchColumnsLoading$ | async)"
    class="w-full flex justify-content-end"
  >
    <p-button
      *ngIf="isValidatedOnce"
      styleClass="m-1 p-button-outlined"
      [disabled]="
        (eventStateService.isUploadEditInProgress$ | async) ||
        (eventStateService.validationLoading$ | async)!
      "
      (onClick)="onDownloadClicked()"
    >
      {{ 'DASHBOARD.UPLOAD_MODAL.DOWNLOAD' | transloco }}</p-button
    >
    <p-button
      styleClass="p-button m-1"
      [disabled]="
        (eventStateService.isUploadEditInProgress$ | async) ||
        (eventStateService.isImportInProgress$ | async) ||
        (eventStateService.validationLoading$ | async)!
      "
      (onClick)="onPrimaryActionClicked()"
    >
      {{ actionLabel | transloco }}</p-button
    >
  </div>
</div>

<ng-template #errorIcon let-errorType="errorType" let-field="field">
  <ng-container *ngIf="field === 'plantation_code' && errorType">
    <app-error-icon [errorType]="errorType"></app-error-icon>
  </ng-container>
</ng-template>

<ng-template #tableLoading>
  <p-skeleton width="100%" height="30rem"></p-skeleton>
</ng-template>
