import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';

@Component({
  selector: 'app-risk-icon',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule],
  templateUrl: './risk-icon.component.html',
  styleUrls: ['./risk-icon.component.scss']
})
export class RiskIconComponent {
  @Input() riskType = '';
  @Input() marginClass = 'mx-2';
  @Input() hasTooltip = true;
  @Input() isClickable = false;
  @Input() iconColor = '';
  @Input() label = '';

  riskTypes = RiskTypesEnum;
}
