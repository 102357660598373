<div class="grid mb-3">
  <div class="col-12">
    <span class="header"
      >{{ riskOverlapLabel }} {{ 'MAP_PAGE.RISK_OVERLAPS' | transloco }}</span
    >
  </div>
</div>
<ng-container
  *ngIf="
    (crdStateService.checkedSelectedPlantationList$ | async)?.length;
    else riskDetailsLoading
  "
>
  <ng-container *ngFor="let chunk of chunkedRiskOverlapDetails">
    <div class="grid risk-cards">
      <div *ngFor="let riskOverlapDetail of chunk" class="col-4">
        <p-card>
          <app-risk-overlap-details
            [riskOverlapDetail]="riskOverlapDetail"
          ></app-risk-overlap-details>
        </p-card>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #riskDetailsLoading>
  <div class="grid">
    <div class="lg:col-4 col-6">
      <app-progress-bar-skeleton
        [width]="'100%'"
        [height]="'24rem'"
      ></app-progress-bar-skeleton>
    </div>
    <div class="lg:col-4 col-6">
      <app-progress-bar-skeleton
        [width]="'100%'"
        [height]="'24rem'"
      ></app-progress-bar-skeleton>
    </div>
    <div class="lg:col-4 col-6">
      <app-progress-bar-skeleton
        [width]="'100%'"
        [height]="'24rem'"
      ></app-progress-bar-skeleton>
    </div>
  </div>
</ng-template>
