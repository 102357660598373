<app-page-header [headerLabel]="header">
  <span class="text-xl font-semibold" specialLabel>{{
    (crdStateService.siDetails$ | async)?.siNumber
  }}</span>
</app-page-header>
<div *ngIf="!isFinalPage" class="grid">
  <div class="col-12">
    <span class="text-lg font-semibold">{{
      'DASHBOARD.SI_LINKING.SUB_HEADER' | transloco
    }}</span>
  </div>
</div>

<div class="grid my-3">
  <div class="col-12">
    <ng-container *ngIf="!isFinalPage; else summaryPage">
      <ng-template *ngTemplateOutlet="plantationTable"></ng-template>
    </ng-container>
    <ng-template #summaryPage>
      <app-si-linking-summary-page
        [columns]="columns"
        [selectedItems]="selectedPlantations"
        (tableLoading)="checkSummaryTableLoading($event)"
      />
    </ng-template>
  </div>
</div>
<div class="flex mt-2 justify-content-end align-items-center">
  <p-button
    [disabled]="(disableBackButton$ | async)!"
    (onClick)="onBackClicked()"
    styleClass="p-button-outlined mx-3"
  >
    <span> {{ 'DASHBOARD.SI_LINKING.BACK' | transloco }} </span>
  </p-button>
  <p-button
    [disabled]="(disablePrimaryButton$ | async)!"
    (onClick)="onPrimaryActionClicked()"
  >
    <span> {{ primaryActionLabel | transloco }} </span>
  </p-button>
  <div class="ml-3" *ngIf="isSiLinkInProgress$ | async">
    <app-progress-spinner />
  </div>
</div>

<ng-template #plantationTable>
  <app-plantation-table
    (selectionChanged)="onPlantationSelectionChanged($event)"
    [isSiLinking]="true"
  >
    <app-dataset-autocomplete [isSiLinkingPage]="true" datasetAutocomplete />
  </app-plantation-table>
</ng-template>
