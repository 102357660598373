<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="flex">
  <span
    class="material-symbols-outlined mr-2 back-button"
    (click)="onBackClicked()"
  >
    arrow_back
  </span>
  <app-page-header [headerLabel]="'Polygon Mapping Settings'"></app-page-header>
</div>

<!-- <div class="flex">
  <app-refresh-button [lastUpdatedDate]="'30/03/2024'"></app-refresh-button>
</div> -->

<div class="settings-card grid my-3">
  <div class="col-12">
    <p-card>
      <div class="flex"><span> GPS Point Location Selection </span></div>
      <form class="flex flex-column" [formGroup]="settingsFormGroup">
        <div class="flex my-3">
          <span>
            Please indicate where the GPS point of your plantation(s) was taken:
          </span>
        </div>
        <div class="flex flex-column">
          <div *ngFor="let option of gpsLocationOptions" class="field-checkbox">
            <p-radioButton
              [inputId]="option.value"
              [value]="option.value"
              formControlName="gpsLocation"
            ></p-radioButton>
            <label [for]="option.value" class="ml-2">{{ option.label }}</label>
          </div>
        </div>
      </form>
    </p-card>
  </div>
</div>

<div class="flex justify-content-end">
  <p-button (onClick)="onCancelClicked()" styleClass="p-button-outlined mr-3"
    >Cancel</p-button
  >
  <p-button
    (onClick)="onSaveChangesClicked()"
    [disabled]="gpsLocation.invalid"
    styleClass="p-button"
    >Save Changes</p-button
  >
</div>
