import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { Subject, takeUntil } from 'rxjs';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import {
  PlantationTableFilters,
  TotalLandArea,
  TotalMappingEffort,
  TotalPlot
} from 'src/app/models/crd-state.interface';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-summary-charts',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    ProgressBarSkeletonComponent,
    TranslocoPipe
  ],
  providers: [DecimalPipe],
  templateUrl: './summary-charts.component.html',
  styleUrls: ['./summary-charts.component.scss']
})
export class SummaryChartsComponent implements OnInit, OnDestroy {
  totalLandAreaData: any;
  totalLandAreaOptions: any;
  totalYield = 0;

  totalMappingEffortData: any;
  totalMappingEffortOptions: any;

  totalPlots: TotalPlot[] = [];
  totalPlotsSum = 0;

  totalLandAreas: TotalLandArea[] = [];
  totalLandAreasSum = 0;

  totalSummaryParams!: { filters: PlantationTableFilters | null };

  destroyed$ = new Subject<void>();

  expandMappingEfforts = true;

  constructor(
    public eventStateService: EventStateService,
    private crdStateService: CrdStateService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.listenToTableFilterChange();
    this.listenToTotalSummaryChange();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  listenToTableFilterChange() {
    this.eventStateService.plantationFiltersPayload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filters) => {
        const params = {
          filters: filters
        };
        this.totalSummaryParams = params;

        this.crdStateService.getTotalSummary(params);
      });

    this.eventStateService.refreshTable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.eventStateService.isStatisticsUpdating = true;
        this.crdStateService.getTotalSummary(this.totalSummaryParams);
      });
  }

  listenToTotalSummaryChange() {
    this.crdStateService.totalSummary$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((totalSummary) => {
        if (totalSummary) {
          this.assignTotalLandAreas(totalSummary?.totalLandAreas);
          this.assignTotalPlots(totalSummary?.totalPlots);
          this.assignTotalMappingEfforts(totalSummary?.totalMappingEfforts);
        }
      });
  }

  assignTotalLandAreas(totalLandAreas: TotalLandArea[]) {
    const barColor = this.utilityService.getComputedStyle('--primary-color');
    const invalidColor = this.utilityService.getComputedStyle('--red');
    const countries = [...totalLandAreas.map((landArea) => landArea.country)];
    const countValue = [
      ...totalLandAreas.map((landArea) => landArea.totalCount)
    ];

    this.totalYield = totalLandAreas.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.totalYield;
    }, 0);

    this.totalLandAreasSum = totalLandAreas.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.area;
      },
      0
    );
    const barColors = [
      ...countries.map((country) =>
        country.toLowerCase() === 'invalid geometry' ? invalidColor : barColor
      )
    ];
    this.totalLandAreaData = {
      labels: countries,
      datasets: [
        {
          backgroundColor: barColors,
          borderColor: barColors,
          data: countValue,
          barThickness: 12
        }
      ]
    };

    const length = countValue.length;
    const aspectRatio = length > 10 ? 0.5 : length > 5 ? 0.7 : 1.5;
    this.totalLandAreaOptions = {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: aspectRatio,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (data: any) => {
              const formattedArea =
                this.decimalPipe.transform(
                  totalLandAreas[data.dataIndex].area,
                  '1.2-2'
                ) ?? 0;
              return `Count: ${
                totalLandAreas[data.dataIndex].totalCount
              } Area: ${formattedArea} ha`;
            }
          }
        }
      },
      scales: {
        x: {
          beginAtZero: true
        },
        y: {
          // barPercentage: 1,
          // categoryPercentage: 0.1,
          ticks: {
            color: '#000'
          }
        }
      }
    };
  }

  assignTotalPlots(totalPlots: TotalPlot[]) {
    this.totalPlots = totalPlots;
    this.totalPlotsSum = totalPlots.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.count;
    }, 0);
  }

  assignTotalMappingEfforts(totalMappingEfforts: TotalMappingEffort[]) {
    const plantationsMapped = [
      ...totalMappingEfforts.map((data) =>
        this.decimalPipe.transform(data.plantationsMapped, '1.0-0')
      )
    ];
    const dateValue = [
      ...totalMappingEfforts.map((data) =>
        this.datePipe.transform(data.day, 'MMM d, y')
      )
    ];

    this.totalMappingEffortData = {
      labels: dateValue,
      datasets: [
        {
          backgroundColor: '#FBC02D',
          borderColor: '#FBC02D',
          data: plantationsMapped
        }
      ]
    };

    this.totalMappingEffortOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (data: any) => {
              const formattedArea =
                this.decimalPipe.transform(
                  totalMappingEfforts[data.dataIndex].totalArea,
                  '1.2-2'
                ) ?? 0;
              const formattedPlantationsMapped =
                this.decimalPipe.transform(
                  totalMappingEfforts[data.dataIndex].plantationsMapped,
                  '1.2-2'
                ) ?? 0;

              const dataSourceTooltip = totalMappingEfforts[data.dataIndex]
                .data_source
                ? `Data source: ${
                    totalMappingEfforts[data.dataIndex].data_source
                  }: ${totalMappingEfforts[data.dataIndex].plantationsMapped}`
                : '';

              const label = [
                `Plantations Mapped: ${formattedPlantationsMapped} Total Area: ${formattedArea} ha`,
                dataSourceTooltip
              ];
              return label;
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            color: '#000'
          }
        }
      }
    };
  }
}
