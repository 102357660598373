import { Injectable } from '@angular/core';
import { utils, writeFile } from 'xlsx';
import { DatePipe } from '@angular/common';
import { DownloadOptionsEnum } from '../enums/download-options.enum';
import { DashboardService } from './data-service/dashboard.service';
import * as saveAs from 'file-saver';
import { EventStateService } from './state-service/event-state.service';
import { ParsedUploadPlantation } from '../models/parsed-upload-plantation.model';
import { UtilityService } from './utility.service';
import { CrdStateService } from './state-service/crd-state.service';
import { combineLatest, concatMap, filter, of, take } from 'rxjs';
import { DynamicDownloadPayload } from '../models/download-risk-report-payload.model';
@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private datePipe: DatePipe,
    private dashboardService: DashboardService,
    private eventStateService: EventStateService,
    private utilityService: UtilityService,
    private crdStateService: CrdStateService
  ) {}

  // for file upload verify tab
  downloadValidatedParsedData(plantations: ParsedUploadPlantation[]) {
    const sheetContent: any = [];
    plantations.forEach((plantation) => {
      if (plantation.error) {
        plantation.error = `${
          plantation.error.type
        } - ${plantation.error.description.toString()}` as any;
      }

      const content = {
        'Plantation Code': plantation.plantation_code,
        Geometry: plantation.geometry,
        'Plantation Name': plantation.plantation_name,
        'Land Area (ha)': plantation.land_area,
        'Date Created (dd/mm/yyyy)': plantation.date_created,
        Error: plantation.error
      };

      sheetContent.push(content);
    });

    const plantationsSheet = utils.json_to_sheet(sheetContent);
    plantationsSheet['!cols'] = this.getAdjustedHeaderColumnWidth(
      sheetContent[0],
      10
    );

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, plantationsSheet, 'plantations');
    const fileName = 'test';

    writeFile(workbook, `${fileName}_${this.getFormattedDateTimeNow()}.xlsx`);
  }

  // TODO: refactor
  dynamicDownload(
    params: DynamicDownloadPayload,
    dynamicDownloadType: DownloadOptionsEnum
  ) {
    combineLatest([
      this.crdStateService.riskProperties$,
      this.crdStateService.selectedPeriod$
    ])
      .pipe(
        filter(
          ([riskProperties, selectedPeriod]) =>
            !!riskProperties && !!selectedPeriod
        ),
        take(1),
        concatMap(([riskProperties, selectedPeriod]) => {
          params.period = selectedPeriod!;

          switch (dynamicDownloadType) {
            case DownloadOptionsEnum.MAPPING_DATA_QUALITY_REPORT:
              return this.dashboardService.downloadMappingDataQualityReport(
                params
              );
            case DownloadOptionsEnum.FULL_RISK_REPORT: {
              const riskColumns: string[] = [];
              riskProperties!.forEach((prop) => {
                const value = `${prop.name}_value as \`Risk ${prop.display_name} Value\``;
                const haOverlap = `${prop.name}_overlap_area as \`Risk ${prop.display_name}  Ha Overlap\``;
                const percOverlap = `${prop.name}_overlap_area_perc as \`Risk ${prop.display_name} Percentage Overlap\``;
                riskColumns.push(value, haOverlap, percOverlap);
              });
              params.riskColumns = riskColumns;
              return this.dashboardService.downloadFullRiskReport(params);
            }
            case DownloadOptionsEnum.PLANTATION_INFORMATION:
              return this.dashboardService.downloadPlantationInformation(
                params
              );
            default:
              return of();
          }
        })
      )
      .subscribe((res) => {
        switch (dynamicDownloadType) {
          case DownloadOptionsEnum.MAPPING_DATA_QUALITY_REPORT:
            this.eventStateService.downloadMappingDataQualityReportLoading =
              false;
            break;
          case DownloadOptionsEnum.FULL_RISK_REPORT:
            this.eventStateService.downloadFullRiskReportLoading = false;
            break;
          case DownloadOptionsEnum.PLANTATION_INFORMATION:
            this.eventStateService.downloadPlantationInformationLoading = false;
            break;
        }

        const fileName = this.utilityService.getFileNameFromHeaders(res);
        saveAs(res.body as Blob, fileName);
      });
  }

  getAdjustedHeaderColumnWidth(valueToIterate: any, lengthToIncrease: number) {
    const cols = [];
    for (const [key, value] of Object.entries(valueToIterate)) {
      cols.push({ wch: key?.length + lengthToIncrease });
    }
    return cols;
  }

  private getFormattedDateTimeNow() {
    return this.datePipe.transform(new Date(), 'MMddyyyyHHmm');
  }
}
