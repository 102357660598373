import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { Subject, map, skip, take, takeUntil } from 'rxjs';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { FormsModule } from '@angular/forms';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { ConfirmDeleteComponent } from 'src/app/shared/confirm-delete/confirm-delete.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ParsedUploadPlantation } from 'src/app/models/parsed-upload-plantation.model';
import { ErrorTypesEnum } from 'src/app/enums/error-types.enum';
import { ErrorIconComponent } from 'src/app/shared/error-icon/error-icon.component';
import { DownloadService } from 'src/app/services/download.service';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { Plantation } from 'src/app/models/crd-state.interface';
import { TruncatePipe } from 'src/pipes/truncate.pipe';

@Component({
  selector: 'app-match-tab',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    ErrorIconComponent,
    TranslocoPipe,
    TruncatePipe
  ],
  templateUrl: './match-tab.component.html',
  styleUrls: ['./match-tab.component.scss']
})
export class MatchTabComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  rowData: ParsedUploadPlantation[] = [];
  clonedRowData: ParsedUploadPlantation[] = [];
  columns: ColumnDefinition[] = [];
  isValidatedOnce = false;
  isValid = false;
  hasErrors = false;

  get canImport() {
    return this.isValidatedOnce && this.isValid;
  }

  get actionLabel() {
    return this.canImport
      ? 'DASHBOARD.UPLOAD_MODAL.IMPORT'
      : 'DASHBOARD.UPLOAD_MODAL.VERIFY';
  }

  get filteredColumns() {
    return [...this.columns.filter((c) => !c.hidden)];
  }

  get tableWidthStyle() {
    const width = this.hasErrors ? '150rem' : '100rem';
    return { 'min-width': width };
  }

  constructor(
    private crdStateService: CrdStateService,
    public eventStateService: EventStateService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private downloadService: DownloadService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.initializeParsedFileData();
    this.initializeColumns();
    this.listenToIsEditInProgress();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeParsedFileData() {
    this.crdStateService.parsedFileData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data) {
          this.isValidatedOnce = false;
          this.rowData = data;
          this.clonedRowData = [...this.rowData];
        }
      });
  }

  initializeColumns() {
    this.crdStateService.uploadFileMappingColumns$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((columns) => {
        if (columns) {
          this.columns = columns;
        }
      });
  }

  listenToIsEditInProgress() {
    this.eventStateService.isUploadEditInProgress$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isInProgress) => {
        if (this.columns.length) {
          this.columns[this.columns.length - 1].style = isInProgress
            ? 'width: 15rem;'
            : 'width: 10rem;';
        }
      });
  }

  onPrimaryActionClicked() {
    if (this.canImport) {
      this.import();
      return;
    }

    this.validateParsedData();
  }

  import() {
    this.eventStateService.isImportInProgress = true;
    this.dashboardService.uploadPlantationData(
      this.crdStateService.crd,
      this.rowData
    );
  }

  onDownloadClicked() {
    const validatedData = [
      ...this.rowData.map((data) => Object.assign({}, { ...data }))
    ];
    this.downloadService.downloadValidatedParsedData(validatedData);
  }

  validateParsedData() {
    this.isValidatedOnce = true;
    this.eventStateService.validationLoading = true;
    this.crdStateService.resetParsedFileErrors();
    this.crdStateService.getParsedFileErrors(this.rowData);
    this.crdStateService.parsedFileErrors$
      .pipe(take(2), skip(1))
      .subscribe((data) => {
        const errorsIsEmpty = data
          ? Object.keys(data?.errors).length === 0
          : true;
        const warningsIsEmpty = Object.keys(data.warnings).length === 0;
        this.hasErrors = !errorsIsEmpty || !warningsIsEmpty;

        this.isValid = errorsIsEmpty;

        if (this.hasErrors) {
          for (const row of this.rowData) {
            const key = row.plantation_code || 'no_plantation_code';
            const warnings = data.warnings[key];
            const errors = data.errors[key];
            const textArray: string[] = [];
            // TODO: refactor
            if (warnings) {
              warnings.forEach((warning: { text: string }) => {
                textArray.push(warning.text);
                const text = textArray;
                row.error = {
                  description: text,
                  type: ErrorTypesEnum.WARNING
                };
              });
            }

            if (errors) {
              errors.forEach((error: { text: string }) => {
                textArray.push(error.text);
                const text = textArray;
                row.error = { description: text, type: ErrorTypesEnum.ERROR };
              });
            }
          }
          this.hideErrorColumn(!this.hasErrors);
        }
      });
  }

  onRowEditInit(rowData: ParsedUploadPlantation, index: number) {
    this.clonedRowData[index] = { ...rowData };
    this.eventStateService.isUploadEditInProgress = true;
  }

  hideErrorColumn(show: boolean) {
    this.columns = this.columns.map((c) => {
      if (c.field === 'error') {
        c.hidden = show;
      }
      return c;
    });
  }

  onDelete(rowData: Plantation, index: number) {
    const modalActionsEvent = new EventEmitter<'delete' | 'cancel'>();
    const confirmDeleteModal = this.dialogService.open(ConfirmDeleteComponent, {
      data: {
        modalActions: modalActionsEvent,
        plantationName: rowData.plantation_name
      },
      width: '502px'
    });

    modalActionsEvent.subscribe((action) => {
      switch (action) {
        case 'delete':
          this.rowData.splice(index, 1);
          confirmDeleteModal.close();
          break;
        case 'cancel':
          confirmDeleteModal.close();
          break;
        default:
          break;
      }
    });
  }
  onRowEditCancel(index: number) {
    this.rowData[index] = this.clonedRowData[index];
    delete this.clonedRowData[index];
    this.eventStateService.isUploadEditInProgress = false;
  }

  onRowEditSave(rowData: ParsedUploadPlantation, index: number) {
    this.isValidatedOnce = false;
    this.rowData[index].land_area = Number(this.rowData[index]?.land_area);
    // this.rowData[index].date_created = rowData.date_created
    //   ? this.datePipe.transform(rowData.date_created, 'dd/MM/yyyy')
    //   : '';
    this.eventStateService.isUploadEditInProgress = false;
  }

  // TODO: use for datepicker
  getFormattedDate(date: any) {
    return typeof date !== 'string'
      ? this.datePipe.transform(date, 'dd/MM/yyyy')
      : date;
  }
}
