export const SETTINGS_LABELS = {
  confirmation: {
    content: `Configuration of GPS location selection and margin of error percentage
        input will affect the risk buffer calculation and can only be configured
        once.`,
    subContent: 'Are you sure you want to proceed?',
    primaryAction: 'Yes, proceed',
    secondaryAction: 'Back to previous'
  },
  redirect: {
    content: `We have detected that you have not done the polygon mapping configurations setup. Please go to the Polygon Mapping Settings from Polygon Mapping page to setup the configurations in order to view risk buffer data.`,
    primaryAction: 'Go to Polygon Mapping Settings',
    secondaryAction: 'Cancel'
  }
};
