export enum GeoJsonTypesEnum {
  // TODO: remove point and polygon - check if used
  POINT = 'Point',
  POLYGON = 'Polygon',
  ST_MULTIPOLYGON = 'st_multipolygon',
  ST_POLYGON = 'st_polygon',
  ST_POINT = 'st_point',
  ST_GEOMETRY_COLLECTION = 'st_geometrycollection',
  ST_MULTIPOINT = 'st_multipoint',
  INVALID_GEOMETRY = 'invalid geometry',
  OTHERS = 'others'
}
