<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="flex card-content flex-column">
  <div class="flex mb-4 align-items-center justify-content-between">
    <span class="header">{{ riskOverlapDetail?.label | transloco }}</span>
    <app-risk-icon
      [isClickable]="true"
      (click)="onRiskIconClicked()"
      [hasTooltip]="false"
      [iconColor]="riskOverlapDetail.color"
    ></app-risk-icon>
  </div>
  <ng-template #modalHeader>
    <div class="flex align-items-center mb-4">
      <app-risk-icon
        [marginClass]="'mr-2'"
        [hasTooltip]="false"
        [riskType]="riskOverlapDetail?.riskType"
      ></app-risk-icon>
      <span class="header">{{ riskOverlapDetail?.label | transloco }}</span>
    </div>
  </ng-template>
  <div class="flex justify-content-between">
    <div class="flex flex-column">
      <span class="total-label mb-1">{{
        'MAP_PAGE.TOTAL_AREAS' | transloco
      }}</span>
      <span class="total mb-2">
        {{
          (riskValueAggregates[0]?.area_calculated_sum | number : '1.2-3') || 0
        }}
      </span>
    </div>
    <div class="flex flex-column">
      <span class="total-label mb-1">Total Count</span>
      <span class="total text-right mb-2">
        {{ riskValueAggregates[0]?.plantation_code_count }}
      </span>
    </div>
  </div>
  <div class="breakdown-modal flex flex-column">
    <span class="mb-1">{{ 'MAP_PAGE.BREAKDOWN' | transloco }} </span>
    <p-progressBar
      *ngIf="(tableLoading$ | async)!"
      mode="indeterminate"
    ></p-progressBar>
    <p-table
      [value]="rowData"
      [lazy]="true"
      [totalRecords]="totalCount"
      (onLazyLoad)="lazyLoadRiskValues($event)"
      [paginator]="true"
      [rows]="10"
      [loading]="(tableLoading$ | async)!"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{
        'DASHBOARD.SHOWING_TABLE_ENTRIES_COUNT'
          | transloco
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      }}"
      [rowsPerPageOptions]="[10, 30, 100]"
    >
      <ng-template pTemplate="header" let-rowIndex="rowIndex">
        <tr>
          <th style="min-width: 10rem" pSortableColumn="percentage">
            {{ 'MAP_PAGE.PERCENTAGE' | transloco }}
            <p-sortIcon field="percentage"></p-sortIcon>
          </th>
          <th style="min-width: 7rem" pSortableColumn="area">
            {{ 'MAP_PAGE.HECTARES' | transloco }}
            <p-sortIcon field="area"></p-sortIcon>
          </th>
          <ng-container
            *ngIf="
              riskOverlapDetail?.riskType === riskTypes.SELF_OVERLAP;
              else notSelfOverlapTh
            "
          >
            <th class="plantation-column">
              {{ 'MAP_PAGE.PLANTATION' | transloco }} 1
            </th>
            <th class="plantation-column">
              {{ 'MAP_PAGE.PLANTATION' | transloco }} 2
            </th>
          </ng-container>
          <ng-template #notSelfOverlapTh>
            <th class="plantation-column" pSortableColumn="plantation_name">
              {{ 'MAP_PAGE.PLANTATION' | transloco }}
              <p-sortIcon field="plantation_name"></p-sortIcon>
            </th>
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-area let-areaIndex="rowIndex">
        <tr>
          <td style="font-weight: 700">{{ area.percentage }}%</td>
          <td>{{ area.area | number : '1.3-3' }}</td>

          <ng-container
            *ngIf="
              riskOverlapDetail?.riskType === riskTypes.SELF_OVERLAP;
              else notSelfOverlapTd
            "
          >
            <td>{{ area.areaName }}</td>

            <td class="plantation-column">
              {{ riskOverlapDetail?.areas[areaIndex]?.overlappingPlantation }}
            </td>
          </ng-container>
          <ng-template #notSelfOverlapTd>
            <td>{{ area.plantation_name }}</td>
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="3">
            <span class="no-risk-label">
              {{ 'MAP_PAGE.NO_RISK_FOUND' | transloco }}</span
            >
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
