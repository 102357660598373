<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="flex card-content flex-column">
  <div class="flex mb-4 align-items-center justify-content-between">
    <span class="header">{{ riskOverlapDetail?.label | transloco }}</span>
    <app-risk-icon
      [isClickable]="true"
      (click)="onRiskIconClicked()"
      [hasTooltip]="false"
      [iconColor]="riskOverlapDetail?.color || ''"
    ></app-risk-icon>
  </div>
  <div class="flex justify-content-between">
    <div class="flex flex-column">
      <span class="total-label mb-1">{{
        'MAP_PAGE.TOTAL_AREAS' | transloco
      }}</span>
      <span
        *ngIf="
          !(aggregatesLoading$ | async) &&
            !(eventStateService.hasError$ | async);
          else aggregatesLoader
        "
        class="total mb-2"
      >
        {{
          (riskValueAggregates[0]?.area_calculated_sum | number : '1.3-3') || 0
        }}
      </span>
    </div>
    <div class="flex flex-column">
      <span class="total-label mb-1">{{
        'MAP_PAGE.TOTAL_COUNT' | transloco
      }}</span>
      <span
        *ngIf="
          !(aggregatesLoading$ | async) &&
            !(eventStateService.hasError$ | async);
          else aggregatesLoader
        "
        class="total text-right mb-2"
      >
        {{ riskValueAggregates[0]?.plantation_code_count | number : '1.0-0' }}
      </span>
    </div>
  </div>
  <div class="breakdown flex flex-column">
    <span class="mb-1">{{ 'MAP_PAGE.BREAKDOWN' | transloco }} </span>
    <p-progressBar
      *ngIf="(tableLoading$ | async)!"
      mode="indeterminate"
    ></p-progressBar>
    <p-table
      #table
      [value]="rowData"
      [lazy]="true"
      (onLazyLoad)="lazyLoadRiskValues($event)"
      [paginator]="false"
      [rows]="3"
      [loading]="
        (tableLoading$ | async)! && !(eventStateService.hasError$ | async)!
      "
    >
      <ng-template pTemplate="header" let-rowIndex="rowIndex">
        <tr>
          <th style="min-width: 10rem" pSortableColumn="percentage">
            {{ 'MAP_PAGE.PERCENTAGE' | transloco }}
            <p-sortIcon field="percentage"></p-sortIcon>
          </th>
          <th style="min-width: 7rem" pSortableColumn="area">
            {{ 'MAP_PAGE.HECTARES' | transloco }}
            <p-sortIcon field="area"></p-sortIcon>
          </th>
          <ng-container
            *ngIf="
              riskOverlapDetail?.riskType === riskTypes.SELF_OVERLAP;
              else notSelfOverlapTh
            "
          >
            <th class="plantation-column">
              {{ 'MAP_PAGE.PLANTATION' | transloco }} 1
            </th>
            <th class="plantation-column">
              {{ 'MAP_PAGE.PLANTATION' | transloco }} 2
            </th>
          </ng-container>
          <ng-template #notSelfOverlapTh>
            <th class="plantation-column" pSortableColumn="plantation_name">
              {{ 'MAP_PAGE.PLANTATION' | transloco }}
              <p-sortIcon field="plantation_name"></p-sortIcon>
            </th>
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-area let-areaIndex="rowIndex">
        <tr>
          <td style="font-weight: 700">{{ area.percentage }}%</td>
          <td>{{ area.area | number : '1.3-3' }}</td>

          <ng-container
            *ngIf="
              riskOverlapDetail?.riskType === riskTypes.SELF_OVERLAP;
              else notSelfOverlapTd
            "
          >
            <td>{{ area.areaName }}</td>

            <td class="plantation-column">
              {{ riskOverlapDetail?.areas[areaIndex]?.overlappingPlantation }}
            </td>
          </ng-container>
          <ng-template #notSelfOverlapTd>
            <td>{{ area.plantation_name }}</td>
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="3">
            <span class="no-risk-label">
              {{ 'MAP_PAGE.NO_RISK_FOUND' | transloco }}</span
            >
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <span
    *ngIf="totalCount > 3"
    class="expand-span view-more"
    (click)="onViewMoreClicked()"
  >
    {{ 'MAP_PAGE.VIEW_MORE' | transloco }}</span
  >
</div>

<ng-template #aggregatesLoader>
  <p-skeleton [height]="'3rem'"></p-skeleton>
</ng-template>
