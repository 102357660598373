<div class="layer-control-container">
  <ng-container
    *ngIf="
      (riskLayers.length && isRiskLayersColorsAssigned) ||
        (eventStateService.hasError$ | async);
      else riskLayersLoading
    "
  >
    <p-accordion
      class="layer-control"
      [activeIndex]="0"
      expandIcon="pi pi-chevron-down"
      collapseIcon="pi pi-chevron-up"
    >
      <p-accordionTab
        header="{{ 'MAP_PAGE.LAYERS' | transloco }}"
        class=""
        iconPos="end"
      >
        <ng-container
          *ngFor="let layer of riskLayers; last as last; index as i"
        >
          <div class="grid" [class.mb-2]="!last">
            <div class="col-2">
              <p-checkbox
                #checkboxes
                [value]="checkedLayer"
                [binary]="true"
                [(ngModel)]="layer.checked"
                [ngClass]="!layer.checked ? 'risk-layer-unchecked' : ''"
                class="mr-3"
                (onChange)="onLayerSelect($event.checked, i)"
              >
                ></p-checkbox
              >
            </div>
            <div class="col-10">
              <span class="label">{{ layer.name }}</span>
            </div>
          </div>
        </ng-container>
      </p-accordionTab>
    </p-accordion>
  </ng-container>
</div>

<ng-template #riskLayersLoading>
  <app-progress-bar-skeleton
    [width]="'15rem'"
    [height]="'7rem'"
  ></app-progress-bar-skeleton>
</ng-template>
