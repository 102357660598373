import { ColumnDefinition } from '../models/column-definition.model';

export const PLANTATION_LIST_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'plantation_name',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PLANTATION_NAME',
    sortable: true,
    editable: true,
    style: 'word-break: break-word;'
  },
  {
    id: 2,
    field: 'risks',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.RISKS',
    editable: false,
    style: 'width: 8rem;'
  },
  {
    id: 3,
    field: 'area_calculated',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.LAND_AREA',
    sortable: true,
    editable: true
  },
  {
    id: 4,
    field: 'country',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.COUNTRY',
    sortable: true
  },
  {
    id: 5,
    field: 'adm_1',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PROVINCE_STATE',
    sortable: true
  },
  {
    id: 6,
    field: 'adm_2',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.DISTRICT',
    sortable: true
  },
  {
    id: 5,
    field: 'adm_3',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.SUB_DISTRICT',
    sortable: true
  },
  {
    id: 6,
    field: 'adm_4',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.VILLAGE',
    sortable: true
  },
  {
    id: 7,
    field: 'date_created',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.DATE_CREATED',
    sortable: true,
    editable: true
  },
  {
    id: 8,
    field: 'data_source',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.DATA_SOURCE',
    sortable: true,
    editable: false
  },
  {
    id: 9,
    field: 'field_team_code',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.FIELD_TEAM_CODE',
    sortable: false,
    editable: false
  },
  {
    id: 10,
    field: 'actions',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.ACTIONS',
    sortable: false,
    editable: false,
    style: 'width: 9rem;'
  }
];

export const QUESTIONNAIRE_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'plantation_name',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PLANTATION_NAME',
    sortable: false,
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 13%;'
  },
  {
    id: 2,
    field: 'land_legality_color',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LAND_USE_RIGHTS',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 3,
    field: 'environmental_protection_color',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.ENVIRONMENTAL_PROTECTION',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 4,
    field: 'third_parties_FPIC_color',
    displayName:
      'DASHBOARD.QUESTIONNAIRE_COLUMNS.THIRD_PARTIES_RIGHTS_AND_FPIC',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 5,
    field: 'labour_rights_color',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LABOUR_RIGHTS',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 6,
    field: 'tax_anti-corruption_trade-customs_color',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.TAX_ANTI_CORRUPTION',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  }
];
