import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CrdInterceptor } from './interceptor/crd.interceptor';
import { PrimengExportsModule } from './primeng-exports.module';
import { MessageService } from 'primeng/api';
import { provideTransloco } from '@jsverse/transloco';
import { APP_INITIALIZERS, TRANSLOCO_CONFIG } from './app.config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PrimengExportsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    DecimalPipe,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CrdInterceptor,
      multi: true
    },
    // TODO: remove - use only transloco module or this
    provideTransloco(TRANSLOCO_CONFIG),
    APP_INITIALIZERS
  ]
})
export class AppModule {}
