import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from '../../primeng-exports.module';
import { CrdStateService } from '../../services/state-service/crd-state.service';
import {
  Plantation,
  RiskOverlapDetail
} from '../../models/crd-state.interface';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { RiskOverlapDetailsComponent } from './risk-overlap-details/risk-overlap-details.component';
import { UtilityService } from 'src/app/services/utility.service';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';
@Component({
  selector: 'app-risk-overlap-panel',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RiskIconComponent,
    RiskOverlapDetailsComponent,
    TranslocoPipe,
    ProgressBarSkeletonComponent
  ],
  templateUrl: './risk-overlap-panel.component.html',
  styleUrls: ['./risk-overlap-panel.component.scss']
})
export class RiskOverlapPanelComponent implements OnInit, OnDestroy {
  selectedPlantations: Plantation[] = [];
  destroyed$ = new Subject<void>();
  riskOverlapDetails: RiskOverlapDetail[] = [];
  chunkedRiskOverlapDetails: RiskOverlapDetail[][] = [];
  flattenedRisks: any[] = [];
  isRiskAssigned$ = new BehaviorSubject<boolean>(false);
  riskTypes = RiskTypesEnum;
  overlapAreaLength: number[] = [];

  get riskOverlapLabel() {
    const selectedPlantationsLength = this.selectedPlantations.filter(
      (plantation) => plantation.checked === true
    ).length;
    return selectedPlantationsLength === 1
      ? this.translocoService.translate('MAP_PAGE.INDIVIDUAL_PLANTATION')
      : this.translocoService.translate('MAP_PAGE.AGGREGATED');
  }

  get hasNoRisk() {
    return this.selectedPlantations
      .filter((plantation) => plantation.checked)
      .every((plantation) => !plantation?.risks?.length);
  }

  constructor(
    public crdStateService: CrdStateService,
    public eventStateService: EventStateService,
    private utilityService: UtilityService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.listenOnCheckSelectedPlantations();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  chunkArray(array: any[], chunkSize: number): any[][] {
    const result: any[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  listenOnCheckSelectedPlantations() {
    combineLatest([
      this.crdStateService.checkedSelectedPlantationList$,
      this.crdStateService.riskProperties$
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([selectedPlantations, riskProperties]) => {
        if (selectedPlantations) {
          const riskOverlapDetails: RiskOverlapDetail[] = [];
          riskProperties?.forEach((prop) => {
            riskOverlapDetails.push({
              label: prop.display_name,
              description: prop.description,
              riskType: prop.name,
              color: prop.color
            });
          });

          this.selectedPlantations = [...selectedPlantations];
          this.riskOverlapDetails = [...riskOverlapDetails];
          this.chunkedRiskOverlapDetails = this.chunkArray(
            this.riskOverlapDetails,
            3
          );
        }
      });
  }
}
