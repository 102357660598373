/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Observable, catchError, combineLatest, delay, map, tap } from 'rxjs';
import { PagedResponse } from '../../models/paged-response.model';
import {
  Dataset,
  FavoriteFilter,
  Plantation,
  PlantationTableFilters,
  RiskProperty,
  TotalLandArea,
  TotalMappingEffort,
  TotalPlot
} from 'src/app/models/crd-state.interface';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventStateService } from '../state-service/event-state.service';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { ParsedUploadPlantation } from 'src/app/models/parsed-upload-plantation.model';
import { UtilityService } from '../utility.service';
import { DeletePlantationPayload } from 'src/app/models/delete-plantation-payload.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableParams } from 'src/app/models/table-params.model';
import { DynamicDownloadPayload } from 'src/app/models/download-risk-report-payload.model';
import { GeoJsonTypesEnum } from 'src/app/enums/geojson-types.enum';
import { AddFavoriteFilterPayload } from 'src/app/models/add-favorite-filter-payload.model';
import { SiLinkingPlantationsPayload } from 'src/app/models/si-linking-payload.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private datePipe: DatePipe,
    private http: HttpClient,
    private eventStateService: EventStateService,
    private utilityService: UtilityService,
    private decimalPipe: DecimalPipe
  ) {}

  getGeometryDataForExport(
    plantationCodes: string[]
  ): Observable<Plantation[]> {
    return this.http.get<Plantation[]>(
      `${
        environment.CRD_API
      }v1/plantations/download/${this.utilityService.constructMultiplePlantationsQuery(
        plantationCodes
      )}`
    );
  }

  downloadFullRiskReport(params: DynamicDownloadPayload) {
    this.eventStateService.downloadFullRiskReportLoading = true;
    const filters = structuredClone(params.filters);
    const orderBy = params?.orderBy ? `&order_by=${params.orderBy}` : '';
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code as `Plantation Code`',
          'plantation_name as `Plantation Name`',
          'country as Country',
          'adm_1 as Province',
          'adm_2 as District',
          'adm_3 as `Sub District`',
          'adm_4 as Village',
          'date_created as `Date Created`',
          'data_source as `Data Source`',
          'field_team_code as `Field Team Code`',
          ...params.riskColumns!
        ]
      }
    };

    return this.http.post<Blob>(
      `${environment.CRD_API}v2/plantations/download-risks/?date=${params.period}${orderBy}`,
      { ...columns, ...filters },
      {
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    );
  }

  // TODO: refactor - simplify method for dynamic downloads
  downloadMappingDataQualityReport(params: DynamicDownloadPayload) {
    this.eventStateService.downloadMappingDataQualityReportLoading = true;
    const filters = structuredClone(params.filters);
    const orderBy = params?.orderBy ? `&order_by=${params.orderBy}` : '';
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code as `Plantation Code`',
          'plantation_name as `Plantation Name`',
          'risk_self_overlap_v1_overlap_area as `Risk Plantation Overlaps Ha Overlap`',
          'risk_self_overlap_v1_overlap_area_perc as `Risk Plantation Overlaps Percentage Overlap`',
          '(select string_agg(plantation_name) from unnest(risk_self_overlap_v1_data)) as `Overlapping Plantations`'
        ]
      }
    };
    filters!['AND']['risk_self_overlap_v1_value'] = [true];

    return this.http.post<Blob>(
      `${environment.CRD_API}v2/plantations/download-risks/?date=${params.period}${orderBy}`,
      { ...columns, ...filters },
      {
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    );
  }

  downloadPlantationInformation(params: DynamicDownloadPayload) {
    this.eventStateService.downloadPlantationInformationLoading = true;
    const plantationCodes = params?.plantationCodes;
    const filters = structuredClone(params.filters);
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code as `Plantation Code`',
          'plantation_name as `Plantation Name`',
          'area_calculated as `Land Area`',
          'country as Country',
          'adm_1 as Province',
          'adm_2 as District',
          'adm_3 as `Sub District`',
          'adm_4 as Village',
          'date_created as `Date Created`',
          'data_source as `Data Source`',
          'field_team_code as `Field Team Code`',
          'ST_ASGEOJSON(IFNULL(risk_buffer_geo, _geo)) as Geometry'
        ]
      }
    };

    if (plantationCodes?.length) {
      filters!['AND']['plantation_code'] = plantationCodes;
    }

    return this.http.post<Blob>(
      `${environment.CRD_API}v2/plantations/download-risks/?date=${params.period}`,
      { ...columns, ...filters },
      {
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    );
  }

  getDatasets(): Observable<Dataset[]> {
    return this.http.get<Dataset[]>(`${environment.CRD_API}v1/datasets/`);
  }

  getPlantationsPaginated(params: {
    tableParams: TableParams;
    riskProperties: RiskProperty[];
  }): Observable<PagedResponse<Plantation>> {
    const orderBy = params.tableParams?.orderBy
      ? `&order_by=${params.tableParams.orderBy}`
      : '';
    const riskColumn = params.riskProperties.map(
      (prop) => `ifnull(${prop.name}_value, false) as ${prop.name}_value`
    );
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code',
          'plantation_name',
          'area_calculated',
          'country',
          'adm_1',
          'adm_2',
          'adm_3',
          'adm_4',
          'date_created',
          'data_source',
          'field_team_code',
          '_docid',
          '_geo_type',
          'ifnull(has_any_risk_value, false) as has_any_risk_value',
          ...riskColumn
        ]
      }
    };
    return this.http
      .post<PagedResponse<Plantation>>(
        `${environment.CRD_API}v2/plantations/?date=${params.tableParams.period}&page=${params.tableParams.page}&page_size=${params.tableParams.pageSize}${orderBy}`,
        { ...columns, ...params.tableParams.filters }
      )
      .pipe(
        map((res) => {
          const results = res.results.map((result) => {
            const formattedDateCreated = this.datePipe.transform(
              result.date_created,
              'MM/dd/yyyy h.mm a'
            )!;
            const formattedLandArea = this.decimalPipe.transform(
              result.area_calculated,
              '1.3-3'
            )!;

            return {
              ...result,
              date_created: formattedDateCreated,
              area_calculated: formattedLandArea,
              isExpanded: false,
              checked: false,
              disabled: false,
              risks: []
            };
          });
          return { ...res, results };
        }),
        tap(() => {
          this.eventStateService.isDashboardTableLoading = false;
        })
      );
  }

  getQuestionnairesPaginated(
    params: TableParams
  ): Observable<PagedResponse<any>> {
    return this.http
      .get<PagedResponse<any>>(
        `${environment.CRD_API}v1/survey-risks?` +
          `&page_size=${params.pageSize}&page_number=${params.page}`
      )
      .pipe(
        tap(() => {
          this.eventStateService.isDashboardTableLoading = false;
        })
      );
  }

  getPlantationListFilters(period: string): Observable<PlantationTableFilters> {
    return this.http
      .get(`${environment.CRD_API}v2/plantations/filters/?date=${period}`)
      .pipe(
        map((filters) => {
          return {
            ...filters
          };
        }),
        map((filters) => this.transformFilterData(filters)),
        tap(() => (this.eventStateService.isPlantationFilterLoading = false))
      );
  }

  transformFilterData(data: any): any {
    const transformed: any = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        transformed[key] = data[key].map((item: string) => {
          const geojsonProps = this.utilityService.getGeoJsonTypeProps(
            item as any
          );
          return {
            label: key === '_geo_type' ? geojsonProps.label : item,
            value: item ?? '',
            type: key === '_geo_type' ? 'geojson' : null
          };
        });
      }
    }

    if (transformed['_geo_type']) {
      transformed['plantation_name'] = transformed['_geo_type'];
    }

    return transformed;
  }

  getTotalSummary(params: any) {
    return combineLatest([
      this.getTotalLandAreas(params),
      this.getTotalPlots(params),
      this.getTotalMappingEfforts(params)
    ]).pipe(
      tap(() => {
        this.eventStateService.isStatisticsUpdating = false;
        this.eventStateService.isTotalSummaryLoading = false;
      }),
      catchError(() => [])
    );
  }

  getTotalLandAreas(params: any): Observable<TotalLandArea[]> {
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'country',
          'ROUND(SUM(area_calculated), 6) AS area',
          'COUNT(1) as totalCount',
          'round(sum(area_calculated)*1.2, 2) as totalYield '
        ]
      }
    };
    return this.http.post<TotalLandArea[]>(
      `${environment.CRD_API}v2/statistics/land-area/total/?date=${params.period}`,
      { ...columns, ...params.filters }
    );
  }

  getTotalPlots(params: any): Observable<TotalPlot[]> {
    return this.http
      .post<TotalPlot[]>(
        `${environment.CRD_API}v2/statistics/plots/total/?date=${params.period}`,
        params.filters
      )
      .pipe(
        map((res) => {
          res.forEach((plot) => {
            const props = this.utilityService.getGeoJsonTypeProps(
              plot.geometry_type as GeoJsonTypesEnum
            );
            plot.label = props.label;
            plot.icon = props.icon;
          });
          return res;
        })
      );
  }

  getTotalMappingEfforts(params: any): Observable<TotalMappingEffort[]> {
    const payload = {
      aggregates: [
        {
          key: '1',
          function: 'count',
          alias: 'plantationsMapped'
        },
        {
          key: 'area_calculated',
          function: 'SUM'
        }
      ],
      GROUP_BY: ['data_source', 'date_trunc(date_created, DAY) as day']
    };
    return this.http
      .post<TotalMappingEffort[]>(
        `${environment.CRD_API}v2/statistics/dynamic/stats/?date=${params.period}&order_by=day`,
        { ...payload, ...params.filters }
      )
      .pipe(
        map((res) => {
          const groupedData = res.reduce((acc: any, curr: any) => {
            const day = curr.day;
            if (!acc[day]) {
              acc[day] = {
                day: day,
                plantationsMapped: 0,
                totalArea: 0,
                data_source: new Set()
              };
            }
            acc[day].plantationsMapped += curr.plantationsMapped;
            acc[day].totalArea += curr.area_calculated_sum;
            if (curr.data_source) {
              acc[day].data_source.add(curr.data_source);
            }
            return acc;
          }, {}) as TotalMappingEffort[];

          const result = Object.values(groupedData).map(
            (entry: TotalMappingEffort) => ({
              day: entry.day,
              plantationsMapped: entry.plantationsMapped,
              totalArea: entry.totalArea,
              data_source: Array.from(entry.data_source).join(', ')
            })
          );

          return result;
        })
      );
  }

  getRiskProperties(): Observable<RiskProperty[]> {
    return this.http.get<RiskProperty[]>(
      `${environment.CRD_API}v2/datasets/risks/`
    );
  }

  getUploadMappingColumns() {
    return this.http.get(`${environment.CRD_API}v1/file-upload/columns/`).pipe(
      delay(2000),
      // TODO: update type
      map((res: any) => {
        this.eventStateService.matchColumnsLoading = false;
        const mappedColumns: ColumnDefinition[] = [
          ...res.columns.map((data: any, index: any) => ({
            id: ++index,
            field: data.column,
            displayName: data.display,
            editable: true,
            style: `width: ${this.getColumnWidth(data.column)}`
          }))
        ];
        mappedColumns.push({
          id: 6,
          field: 'error',
          displayName: 'Error',
          hidden: true,
          style: 'width: 30rem;',
          sortable: true
        });
        mappedColumns.push({
          id: 7,
          field: 'actions',
          displayName: 'Actions',
          style: 'width: 10rem;'
        });

        return mappedColumns;
      })
    );
  }

  validatePlantationData(
    dataset: string,
    parsedData: ParsedUploadPlantation[]
  ) {
    return this.http
      .post(`${environment.CRD_API}v1/plantation-data/validate/`, {
        dataset: dataset,
        plantation_data: parsedData
      })
      .pipe(
        tap(() => {
          this.eventStateService.validationLoading = false;
        })
      );
  }

  uploadPlantationData(dataset: string, data: ParsedUploadPlantation[]) {
    return this.http
      .post(`${environment.CRD_API}v1/plantation-data/upload/`, {
        dataset: dataset,
        plantation_data: data
      })
      .subscribe(() => {
        this.eventStateService.closeUploadModal = true;
        this.eventStateService.isImportInProgress = false;
        this.utilityService.emitToast({
          isSuccess: true,
          message: 'File is processing..'
        });
      });
  }

  deletePlantation(
    payload: DeletePlantationPayload,
    deleteModal: DynamicDialogRef
  ) {
    const queryParams =
      `?plantation_table=${payload.plantation_table}` +
      `&plantation_code=${payload.plantation_code}` +
      `&dataset=${payload.dataset}`;
    return this.http
      .delete(`${environment.CRD_API}v1/plantations/${queryParams}`)
      .subscribe({
        next: () => {
          this.eventStateService.refreshTable = true;
          this.utilityService.emitToast({
            isSuccess: true,
            message: 'Plantation deleted successfully'
          });
        },
        complete: () => {
          this.eventStateService.isDeletePlantationLoading = false;
          deleteModal.close();
        }
      });
  }

  addFavoriteFilter(payload: AddFavoriteFilterPayload) {
    return this.http.post(
      `${environment.CRD_API}v2/filters/favorites/?dataset=${payload.dataset}`,
      payload
    );
  }

  getFavoritePlantationFilters(): Observable<FavoriteFilter[]> {
    return this.http.get<FavoriteFilter[]>(
      `${environment.CRD_API}v2/filters/favorites/`
    );
  }

  linkPlantationToSi(payload: SiLinkingPlantationsPayload): Observable<any> {
    return this.http
      .post(
        `${environment.TRACEABILITY_API}si/link-plantations-to-si/`,
        payload
      )
      .pipe(
        tap(() => {
          // this.eventStateService.isLinkPlantationLoading = false;
          // this.eventStateService.isPlantationLinked = true;
          // this.utilityService.emitToast({
          //   message: 'Linked successfully.',
          //   isSuccess: true,
          // });
        })
      );
  }

  private getColumnWidth(field: string) {
    switch (field) {
      case 'geometry':
        return '30rem';
    }
    return '10rem';
  }
}
