<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<ng-container
  *ngIf="
    selectedPlantations.length || (eventStateService.hasError$ | async);
    else polygonsLoading
  "
>
  <p-card class="plantation-card">
    <div class="mb-3 flex align-items-center">
      <span class="header mr-4">{{
        'MAP_PAGE.PLANTATIONS_INFORMATION' | transloco
      }}</span>
      <!-- <app-risk-loader></app-risk-loader> -->
    </div>
    <div *ngFor="let plantation of selectedPlantations">
      <div class="mb-4">
        <div class="flex justify-content-between">
          <div class="flex align-items-center">
            <p-checkbox
              [value]="plantation"
              (onChange)="onPlantationChecked()"
              [binary]="true"
              [(ngModel)]="plantation.checked"
            ></p-checkbox>
            <span
              class="plantation-name ml-3"
              (click)="onZoomPlantation(plantation)"
              >{{ plantation.plantation_name }}</span
            >
          </div>
          <div class="flex align-items-center">
            <ng-container *ngIf="plantation?.risks?.length">
              <ng-container *ngFor="let risk of plantation?.risks">
                <app-risk-icon
                  [iconColor]="risk.color!"
                  [label]="risk.display_name!"
                  [marginClass]="'mx-1'"
                ></app-risk-icon>
              </ng-container>
            </ng-container>
            <i
              class="pi chevron-icon action-icon ml-2"
              [ngClass]="
                plantation.isExpanded ? 'pi-chevron-down' : 'pi-chevron-right'
              "
              (click)="onToggleExpand(plantation)"
            ></i>
          </div>
        </div>
        <div class="flex mt-2 plantation-details" *ngIf="plantation.isExpanded">
          <div class="flex flex-column">
            <div class="my-1" *ngFor="let detail of plantationDetails">
              <span class="label"> {{ detail.label }}: </span>
              <span class="value"> {{ plantation[detail.field] }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</ng-container>

<ng-template #polygonsLoading>
  <app-progress-bar-skeleton
    [width]="'100%'"
    [height]="'40rem'"
  ></app-progress-bar-skeleton>
</ng-template>
