import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-confirm-delete',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    ProgressSpinnerComponent,
    TranslocoPipe
  ],
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnDestroy {
  get plantationName() {
    return this.dialogConfig.data?.plantationName;
  }

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public eventStateService: EventStateService
  ) {}

  ngOnDestroy(): void {
    this.eventStateService.isDeletePlantationLoading = false;
  }

  onDelete() {
    this.dialogConfig.data.modalActions.emit('delete');
  }

  onCancel() {
    this.dialogConfig.data.modalActions.emit('cancel');
  }
}
