import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-risk-overlap-info',
  standalone: true,
  imports: [CommonModule, RiskIconComponent, TranslocoPipe],
  templateUrl: './risk-overlap-info.component.html',
  styleUrls: ['./risk-overlap-info.component.scss']
})
export class RiskOverlapInfoComponent implements OnInit {
  riskOverlapDetail: any;
  riskTypes = RiskTypesEnum;
  constructor(public dialogConfig: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.dialogConfig.data) {
      this.riskOverlapDetail = this.dialogConfig.data;
    }
  }
}
