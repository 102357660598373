export const environment = {
  production: false,
  isCn: false,
  mapboxToken:
    'pk.eyJ1IjoiYnJ5YW5oYyIsImEiOiJjbG5lN2hhd3IwZHA0MnNtamFvenpqMjR5In0.v6IJPRIgSL1cjD2WMI75VA',
  CRD_API: 'https://api-traceability-crd-development.agridence.com/api/',
  CRD_API_CN: 'https://api-traceability-crd-development.agridencecn.com/api/',
  TRACEABILITY_API:
    'https://traceability-development.agridence.com/api/v2/traceability/service/',
  TRACEABILITY_API_CN:
    'https://traceability-development.agridencecn.com/api/v2/traceability/service/',
  ARP_API: 'https://api-platform-testing.agridence.com/api/v1/',
  ARP_API_CN: 'https://api-platform-testing.agridencecn.com/api/v1/',
  USER_CONFIG:
    'https://assets.agridence.com/configs/development/{tenantId}/config.json'
};
