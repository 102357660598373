/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileDndDirective } from 'src/app/directives/file-dnd.directive';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FileSizePipe } from 'src/pipes/file-size.pipe';
import * as XLSX from 'xlsx';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { REQUIRED_COLUMNS } from 'src/app/constants/required-columns.const';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { isGeoJSONObject } from 'geojson-validation';
import { GeoJSONGeometry, stringify } from 'wellknown';
import { Feature, GeoJsonModel } from 'src/app/models/geojson.model';
import { ParsedUploadPlantation } from 'src/app/models/parsed-upload-plantation.model';
import saveAs from 'file-saver';
import { DownloadTemplateOption } from 'src/app/models/download-template-option.model';

@Component({
  selector: 'app-import-tab',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FileDndDirective,
    FileSizePipe,
    TranslocoPipe
  ],
  templateUrl: './import-tab.component.html',
  styleUrls: ['./import-tab.component.scss']
})
export class ImportTabComponent {
  @Output() isFileInvalid = new EventEmitter<boolean>();
  @Output() nextClicked = new EventEmitter<void>();
  @ViewChild('inputFile') inputFile!: ElementRef;
  uploadedFile!: File | null;
  hasError = false;
  actionLabel = 'DASHBOARD.UPLOAD_MODAL.CONTINUE_TO_ORGANIZE';
  errorMessageTemplate = 'DASHBOARD.UPLOAD_MODAL.PLEASE_REUPLOAD_YOUR_FILE';
  errorMessage = '';
  isDataParsed = false;
  downloadTemplateOptions: DownloadTemplateOption[] = [
    {
      label: this.translocoService.translate(
        'DASHBOARD.UPLOAD_MODAL.EXCEL_TEMPLATE'
      ),
      fileName: 'Plantation Data Upload Template.xlsx',
      filePath:
        'https://assets.agridence.com/docs-assets/traceability/plantation-data-upload%20template.xlsx'
    },
    {
      label: this.translocoService.translate(
        'DASHBOARD.UPLOAD_MODAL.JSON_TEMPLATE'
      ),
      fileName: 'sample-geojson',
      filePath:
        'https://assets.agridence.com/docs-assets/traceability/sample-geojson.json'
    }
  ];

  get acceptedFileTypes() {
    return ['.csv', '.xls', '.xlsx', '.json', '.geojson'];
  }

  get xlsxExtensions() {
    return ['.csv', '.xls', '.xlsx'];
  }

  get formattedAcceptedFileTypes() {
    return this.acceptedFileTypes.join(',');
  }

  constructor(
    private dialogRef: DynamicDialogRef,
    private crdStateService: CrdStateService,
    private datePipe: DatePipe,
    private translocoService: TranslocoService
  ) {}

  onBackClicked() {
    this.dialogRef.close();
  }

  onFileSelected(event: any, isFromFileDrop = false) {
    this.uploadedFile = isFromFileDrop ? event[0] : event.target.files[0];
    if (this.uploadedFile) {
      this.uploadValidation(this.uploadedFile);
    }
  }

  uploadValidation(file: File) {
    this.isDataParsed = false;
    if (file instanceof FileList) {
      if (file.length > 1) {
        this.hasError = true;
        this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.TRY_AGAIN';
        this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.UPLOAD_1_FILE_ONLY';
        return;
      }
      file = file[0];
    }

    if (
      !(this.fileExtensionValidation(file) && this.fileSizeValidation(file))
    ) {
      this.isDataParsed = true;
      this.hasError = true;
      this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.TRY_AGAIN';
      return;
    }
    this.parseUploadedFile(this.uploadedFile!);
  }

  fileExtensionValidation(file: File) {
    const fileExtension = this.getFileExtension(file.name);
    if (fileExtension) {
      if (!this.acceptedFileTypes.includes(fileExtension)) {
        this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.WRONG_FORMAT';
        return false;
      }
      return true;
    }
    return false;
  }

  fileSizeValidation(file: File) {
    const maxFileSizeInBytes = 10 * 1024 * 1024; // 10 MB
    if (file.size > maxFileSizeInBytes) {
      this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.FILE_SIZE_EXCEEDED';
      return false;
    }
    return true;
  }

  onActionClicked() {
    if (this.hasError) {
      this.hasError = false;
      this.uploadedFile = null;
      return;
    }
    this.nextClicked.emit();
    this.isFileInvalid.emit(this.hasError);
  }

  parseUploadedFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      try {
        let data = e.target.result;
        data = data.replace(/^\xEF\xBB\xBF/, '');

        if (this.xlsxExtensions.includes(this.getFileExtension(file.name))) {
          this.parseXlsx(data);
        } else {
          this.parseJson(data);
        }
      } catch (error) {
        console.error(error);
        // will only go here if json file is invalid - missing brackets, commas, etc.
        this.hasError = true;
        this.isDataParsed = true;
        this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.TRY_AGAIN';
        this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.INVALID_JSON_FILE';
      }
    };
    fileReader.readAsBinaryString(file);
  }

  parseJson(data: any) {
    const parsedData: GeoJsonModel = JSON.parse(data);
    const isValidGeoJson = isGeoJSONObject(parsedData);

    const hasRequiredProperties = parsedData.features.every(
      ({ properties }) => properties?.ProductionPlace && properties?.Area
    );

    parsedData.features.forEach((feature, index) => {
      if (!isGeoJSONObject(feature)) {
        console.error(
          `invalid geojson - index: ${index}  production place:  ${feature.properties?.ProductionPlace} `
        );
      }

      if (!feature.properties?.ProductionPlace && !feature.properties?.Area) {
        console.error(
          `missing required properties` +
            `- index: ${index} production place: ${feature.properties?.ProductionPlace} `
        );
      }
    });

    if (!isValidGeoJson || !hasRequiredProperties) {
      this.hasError = true;
      this.errorMessage = !isValidGeoJson
        ? 'Invalid geojson'
        : 'Missing required property in geojson';
      this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.TRY_AGAIN';
    } else {
      const mappedData: ParsedUploadPlantation[] = parsedData.features.map(
        ({ properties, geometry }, index: number) => ({
          id: ++index,
          plantation_code: properties?.ProductionPlace,
          plantation_name: properties?.ProductionPlace,
          geometry: stringify(geometry as GeoJSONGeometry),
          date_created:
            properties?.DateCreated ||
            this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
          error: null,
          land_area: +properties?.Area
        })
      );

      this.crdStateService.setParsedFileData(mappedData);
      this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.CONTINUE_TO_ORGANIZE';
    }

    this.isDataParsed = true;
  }

  parseXlsx(data: any) {
    const jsonOpts = {
      defval: '',
      blankrows: true,
      raw: false,
      dateNF: 'd"/"m"/"yyyy'
    };
    const workbook = XLSX.read(data, {
      type: 'binary'
    });
    workbook.SheetNames.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils
        .sheet_to_json(worksheet, jsonOpts)
        .filter(
          (data: any) =>
            data['Plantation Code'] ||
            data['Plantation Name'] ||
            data['Geometry'] ||
            data['Date Created'] ||
            data['Land Area (ha)']
        );
      if (this.isHeadersValid(parsedData)) {
        const mappedData: ParsedUploadPlantation[] = parsedData.map(
          (data: any, index: number) => {
            return {
              id: ++index,
              plantation_code: data['Plantation Code'] || null,
              plantation_name: data['Plantation Name'] || null,
              geometry: data['Geometry'] || null,
              date_created: data['Date Created']?.trim() || null,
              error: null,
              land_area: Number(data['Land Area (ha)']) || null
            };
          }
        );
        this.hasError = false;
        this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.CONTINUE_TO_ORGANIZE';
        this.crdStateService.setParsedFileData(mappedData);
      } else {
        this.hasError = true;
        this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.TRY_AGAIN';
        this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.INVALID_FILE_HEADERS';
      }
    });
    this.isDataParsed = true;
  }

  isHeadersValid(data: any[]) {
    if (!data.length) {
      return false;
    }

    for (let i = 0; i < data.length; i++) {
      const valid = REQUIRED_COLUMNS.every((prop) =>
        data[i].hasOwnProperty(prop)
      );
      if (!valid) {
        return false;
      }
    }

    return true;
  }

  onFileDelete(event: Event) {
    this.uploadedFile = null;
    this.isFileInvalid.emit(true);
    this.isDataParsed = false;
    event.stopPropagation();
  }

  onFileSelect() {
    if (this.uploadedFile) {
      return;
    }
    this.inputFile.nativeElement.click();
  }

  onFileDropped(file: File[]) {
    this.onFileSelected(file, true);
  }

  getFileExtension(filename: string): string {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return '';
    }

    const extension = filename.slice(dotIndex + 1);
    return `.${extension.toLowerCase()}`;
  }

  downloadTemplate(template: DownloadTemplateOption): void {
    fetch(template.filePath)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, template.fileName);
      })
      .catch((error) => {
        console.error('Download failed', error);
      });
  }
}
