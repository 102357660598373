import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { SkeletonModule } from 'primeng/skeleton';
import { ChipsModule } from 'primeng/chips';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef
} from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';

import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChartModule } from 'primeng/chart';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';

const PRIMENG_MODULES = [
  ButtonModule,
  CardModule,
  TableModule,
  DropdownModule,
  DividerModule,
  InputTextModule,
  CheckboxModule,
  DialogModule,
  DynamicDialogModule,
  ProgressBarModule,
  InputTextareaModule,
  ProgressSpinnerModule,
  ToastModule,
  TabViewModule,
  SkeletonModule,
  ChipsModule,
  MultiSelectModule,
  AccordionModule,
  OverlayPanelModule,
  DynamicDialogModule,
  TooltipModule,
  FileUploadModule,
  CalendarModule,
  RadioButtonModule,
  InputSwitchModule,
  ChartModule,
  BlockUIModule,
  PanelModule,
  AutoCompleteModule
];

@NgModule({
  declarations: [],
  imports: [...PRIMENG_MODULES],
  exports: [...PRIMENG_MODULES],
  providers: [DialogService, DynamicDialogConfig, DynamicDialogRef]
})
export class PrimengExportsModule {}
