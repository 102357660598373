import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RiskOverlapInfoComponent } from '../risk-overlap-info/risk-overlap-info.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  combineLatest,
  of,
  switchMap,
  takeUntil
} from 'rxjs';
import {
  Plantation,
  RiskOverlapDetail,
  RiskValueAggregate
} from 'src/app/models/crd-state.interface';
import { RiskOverlapDetailsModalComponent } from '../risk-overlap-details-modal/risk-overlap-details-modal.component';
import { EventStateService } from 'src/app/services/state-service/event-state.service';

@Component({
  selector: 'app-risk-overlap-details',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RiskIconComponent,
    TranslocoPipe
  ],
  templateUrl: './risk-overlap-details.component.html',
  styleUrls: ['./risk-overlap-details.component.scss']
})
export class RiskOverlapDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('table') table!: Table;
  @Input() riskOverlapDetail!: RiskOverlapDetail;
  riskTypes = RiskTypesEnum;
  destroyed$ = new Subject<void>();
  selectedPlantations: Plantation[] = [];
  selectedPeriod = '';
  tableLoading$ = new BehaviorSubject(true);
  aggregatesLoading$ = new BehaviorSubject(true);
  isInitialLoad = true;
  totalCount = 0;
  riskValueAggregates: RiskValueAggregate[] = [];

  rowData: any[] = [];

  constructor(
    private dialogService: DialogService,
    public dialogConfig: DynamicDialogConfig,
    public crdStateService: CrdStateService,
    public eventStateService: EventStateService
  ) {}

  ngOnInit(): void {
    this.initializeRiskValues();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeRiskValues() {
    combineLatest([
      this.crdStateService.checkedSelectedPlantationList$,
      this.crdStateService.selectedPeriod$
    ])
      .pipe(
        switchMap(([checkedPlantations, selectedPeriod]) => {
          if (checkedPlantations) {
            this.selectedPlantations = [
              ...checkedPlantations.filter((plantation) => plantation.checked)
            ];
          }

          if (this.selectedPlantations.length) {
            const plantationCodes = this.selectedPlantations.map(
              (plantation) => plantation.plantation_code
            );

            if (selectedPeriod) {
              this.selectedPeriod = selectedPeriod;
            }

            const riskValueParams = {
              plantationCodes,
              riskType: this.riskOverlapDetail.riskType
            };

            return this.crdStateService.getRiskValueAggregates(riskValueParams);
          }

          return of(null);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((riskValueAggregates) => {
        if (riskValueAggregates) {
          this.riskValueAggregates = riskValueAggregates;
          this.aggregatesLoading$.next(false);
        }

        if (!this.isInitialLoad) {
          this.table.ngOnInit();
        }

        this.isInitialLoad = false;
      });
  }

  lazyLoadRiskValues(event: TableLazyLoadEvent) {
    this.tableLoading$.next(true);
    const sortOrder = event.sortOrder === -1 ? '-' : '';
    const sort = `${sortOrder}${event?.sortField ?? ''}`;
    this.crdStateService
      .getPagedRiskValues({
        pageSize: 3,
        page: 1,
        plantationCodes: [
          ...this.selectedPlantations.map(
            (plantation) => plantation.plantation_code
          )
        ],
        riskType: this.riskOverlapDetail.riskType,
        period: this.selectedPeriod,
        orderBy: sort
      })
      ?.pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.rowData = res.results;
        this.totalCount = res.count;
        this.tableLoading$.next(false);
      });
  }

  getMaxOverlap(overlapAreas: any) {
    const maxLengthPlantation = overlapAreas.reduce((max: any, current: any) =>
      current.overlappingPlantations.length > max.overlappingPlantations.length
        ? current
        : max
    );

    return maxLengthPlantation;
  }

  onViewMoreClicked() {
    this.dialogService.open(RiskOverlapDetailsModalComponent, {
      data: {
        riskOverlapDetail: this.riskOverlapDetail,
        selectedPlantations: this.selectedPlantations,
        selectedPeriod: this.selectedPeriod,
        riskValueAggregates: this.riskValueAggregates
      },
      width: '50rem',
      height: '46rem'
    });
  }

  onRiskIconClicked() {
    if (this.riskOverlapDetail.riskType === this.riskTypes.WDPA) {
      return;
    }
    this.dialogService.open(RiskOverlapInfoComponent, {
      data: this.riskOverlapDetail,
      width: '50rem'
    });
  }
}
