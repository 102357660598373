import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [CommonModule, TranslocoPipe],
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() headerLabel = 'DASHBOARD.POLYGON_MAPPING';

  screens = ScreenEnum;
  constructor(public crdStateService: CrdStateService) {}

  onSettingsClicked() {
    this.crdStateService.setCurrentScreen(ScreenEnum.SETTINGS);
  }
}
