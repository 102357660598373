<div class="flex flex-column justify-content-between h-full">
  <div class="flex flex-column mx-4">
    <span class="mt-3 mb-5 font-semibold">{{
      'DASHBOARD.FAVORITE_FILTER_MODAL.PLEASE_CREATE_A_NAME_FOR_THIS_FILTER'
        | transloco
    }}</span>
    <div class="flex flex-column">
      <label for="filterName" class="font-semibold mb-2">{{
        'DASHBOARD.FAVORITE_FILTER_MODAL.FILTER_NAME' | transloco
      }}</label>
      <input
        pInputText
        id="filterName"
        [(ngModel)]="filterName"
        [disabled]="isLoading"
        placeholder="{{
          'DASHBOARD.FAVORITE_FILTER_MODAL.ENTER_FILTER_NAME' | transloco
        }}"
      />
    </div>
  </div>

  <div class="modal-action-section">
    <p-button (onClick)="onCancel()" styleClass="p-button-outlined mt-4">{{
      'DASHBOARD.FAVORITE_FILTER_MODAL.CANCEL' | transloco
    }}</p-button>
    <p-button
      (onClick)="onDone()"
      [disabled]="!filterName || isLoading"
      styleClass="mt-4 mx-4"
      >{{ 'DASHBOARD.FAVORITE_FILTER_MODAL.DONE' | transloco }}</p-button
    >
  </div>
</div>
