import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorTypesEnum } from 'src/app/enums/error-types.enum';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';

@Component({
  selector: 'app-error-icon',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule],
  templateUrl: './error-icon.component.html',
  styleUrls: ['./error-icon.component.scss']
})
export class ErrorIconComponent {
  @Input({ required: true }) errorType!: ErrorTypesEnum;
  @Input() marginClass = 'mx-2';
  @Input() hasTooltip = true;
  @Input() isClickable = false;

  errorTypes = ErrorTypesEnum;

  getErrorIconTooltip(errorType: ErrorTypesEnum) {
    switch (errorType) {
      case ErrorTypesEnum.WARNING:
        return 'Medium error';
      case ErrorTypesEnum.ERROR:
        return 'High error';
      default:
        return '';
    }
  }
}
