import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { TableLazyLoadEvent } from 'primeng/table';
import { Plantation } from 'src/app/models/crd-state.interface';
import { TranslocoPipe } from '@jsverse/transloco';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { RiskOverlapInfoComponent } from '../risk-overlap-info/risk-overlap-info.component';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';

// TODO: refactor whole component - remove repeated codes from details component
@Component({
  selector: 'app-risk-overlap-details-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RiskIconComponent,
    TranslocoPipe,
    ProgressBarSkeletonComponent
  ],
  templateUrl: './risk-overlap-details-modal.component.html',
  styleUrls: ['./risk-overlap-details-modal.component.scss']
})
export class RiskOverlapDetailsModalComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  riskTypes = RiskTypesEnum;
  rowData: any[] = [];
  totalCount = 0;
  tableLoading$ = new BehaviorSubject(true);

  get selectedPlantations() {
    return this.dialogConfig.data.selectedPlantations;
  }

  get selectedPeriod() {
    return this.dialogConfig.data.selectedPeriod;
  }

  get riskOverlapDetail() {
    return this.dialogConfig.data.riskOverlapDetail;
  }

  get riskValueAggregates() {
    return this.dialogConfig.data.riskValueAggregates;
  }

  constructor(
    private dialogService: DialogService,
    private dialogConfig: DynamicDialogConfig,
    public crdStateService: CrdStateService
  ) {}

  ngOnInit(): void {
    this.initializePagedRiskValues();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializePagedRiskValues() {
    this.crdStateService.pagedRiskValuesModal$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.rowData = res.results;
        this.totalCount = res.count;
        this.tableLoading$.next(false);
      });
  }

  lazyLoadRiskValues(event: TableLazyLoadEvent) {
    this.tableLoading$.next(true);
    const pageNumber = event?.first ?? 1;
    const pageSize = event?.rows ?? 10;
    const sortOrder = event.sortOrder === -1 ? '-' : '';
    const sort = `${sortOrder}${event?.sortField ?? ''}`;
    const tableParams = {
      page: Math.floor((pageNumber + pageSize) / pageSize),
      pageSize: pageSize,
      plantationCodes: [
        ...this.selectedPlantations.map(
          (plantation: Plantation) => plantation.plantation_code
        )
      ],
      riskType: this.riskOverlapDetail.riskType,
      period: this.selectedPeriod,
      orderBy: sort,
      isModal: true
    };
    this.crdStateService
      .getPagedRiskValues(tableParams)
      ?.pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.rowData = res.results;
        this.totalCount = res.count;
        this.tableLoading$.next(false);
      });
  }

  onRiskIconClicked() {
    if (this.riskOverlapDetail.riskType === this.riskTypes.WDPA) {
      return;
    }
    this.dialogService.open(RiskOverlapInfoComponent, {
      data: this.riskOverlapDetail,
      width: '50rem'
    });
  }
}
