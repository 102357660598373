import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  Popup,
  LngLatLike,
  LngLatBounds,
  Marker
} from 'mapbox-gl';
import { CrdStateService } from '../../services/state-service/crd-state.service';
import { environment } from 'src/environments/environment';
import { LayersMapControlComponent } from '../layers-map-control/layers-map-control.component';
import { Plantation, RiskLayer } from 'src/app/models/crd-state.interface';
import { Subject, skip, takeUntil } from 'rxjs';
import { bbox, BBox } from '@turf/turf';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';
import { GeoJsonTypesEnum } from 'src/app/enums/geojson-types.enum';
import { RiskBufferToggleComponent } from '../risk-buffer-toggle/risk-buffer-toggle.component';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    CommonModule,
    LayersMapControlComponent,
    PrimengExportsModule,
    ProgressBarSkeletonComponent,
    RiskBufferToggleComponent
  ],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild('map') mapElement!: ElementRef;
  map!: Map;
  lat = 0;
  long = 0;
  isPolygonFirstInitialized = true;

  destroyed$ = new Subject<void>();
  boundingBoxValues: any[] = [];
  isInitialLoad = true;
  markerCoordinates = new Set<Marker>();

  constructor(
    public crdStateService: CrdStateService,
    private eventStateService: EventStateService
  ) {}

  ngOnInit(): void {
    this.initializeMap();
    this.listenToZoomPlantation();
  }

  ngOnDestroy(): void {
    this.crdStateService.setMapStyle('');
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeMap() {
    this.crdStateService.mapStyle$
      .pipe(takeUntil(this.destroyed$), skip(1))
      .subscribe((mapStyle) => {
        this.map = new Map({
          accessToken: environment.mapboxToken,
          container: 'map',
          style: mapStyle,
          renderWorldCopies: false,
          // whole earth zoom
          zoom: 1,
          center: [this.lat, this.long]
        });

        this.map.addControl(new FullscreenControl(), 'top-right');
        this.map.addControl(new NavigationControl(), 'top-right');

        this.map.on('load', () => {
          this.loadPlantationSourcesAndLayers();
          this.loadRiskSourcesAndLayers();
          this.loadSelfOverlappingSourcesAndLayers();
        });
      });
  }

  loadPlantationSourcesAndLayers() {
    this.crdStateService.checkedSelectedPlantationList$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((plantations) => {
        if (plantations?.length) {
          const layers = this.getLayers<Plantation>(plantations);
          this.removePlantationSourcesAndLayers(layers.uncheckedLayers);
          this.addPlantationSourcesAndLayers(layers.checkedLayers);
        }
      });
  }

  loadRiskSourcesAndLayers() {
    this.crdStateService.riskLayers$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((riskLayers) => {
        const layers = this.getLayers<RiskLayer>(riskLayers);

        this.removeRiskRoucesAndLayers(layers.uncheckedLayers);
        this.addRiskSourcesAndLayers(layers.checkedLayers);
      });
  }

  loadSelfOverlappingSourcesAndLayers() {
    this.crdStateService.selfOverlapGeometryData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        res.forEach((data) => {
          if (!this.map.getSource(data.plantation_code)) {
            this.map.addSource(data.plantation_code, {
              type: 'geojson',
              data: data._geo as any
            });

            this.map.addLayer({
              id: data.plantation_code,
              type: 'fill',
              source: data.plantation_code,
              layout: {},
              paint: {
                // TODO: confirm color for overlapping plantations
                'fill-color': 'red',
                'fill-opacity': 0.5
              }
            });
          }
        });
      });
  }

  getLayers<T>(layers: T[]) {
    const checkedLayers = [...layers].filter((layer: any) => layer.checked);
    const uncheckedLayers = [...layers].filter((layer: any) => !layer.checked);

    return {
      checkedLayers: checkedLayers,
      uncheckedLayers: uncheckedLayers
    };
  }

  removeRiskRoucesAndLayers(layers: RiskLayer[]) {
    layers.forEach((layer) => {
      if (this.map.getSource(layer.id)) {
        this.map.removeLayer(layer.id);
        this.map.removeSource(layer.id);
      }
    });
  }

  addRiskSourcesAndLayers(layers: RiskLayer[]) {
    layers.forEach((layer) => {
      if (!this.map.getSource(layer.id)) {
        this.map.addSource(layer.id, {
          type: layer.type as any,
          tiles: [layer.dataurl]
        });
        this.map.addLayer({
          id: layer.id,
          type: layer.type as any,
          source: layer.id,
          minzoom: 0,
          maxzoom: 22
        });
      }
    });
  }

  removePlantationSourcesAndLayers(plantations: Plantation[]) {
    plantations.forEach((plantation) => {
      const plantationId = plantation.plantation_code;
      if (this.map.getSource(plantationId)) {
        this.map.removeLayer(plantationId);
        this.map.removeSource(plantationId);
      }
      this.markerCoordinates.forEach((marker) => {
        if (
          marker.getLngLat().lng === plantation.mapData?.centerCoordinates[0] &&
          marker.getLngLat().lat === plantation.mapData?.centerCoordinates[1]
        ) {
          marker.remove();
        }
      });
    });
  }

  addPlantationSourcesAndLayers(plantations: Plantation[]) {
    const boundingBoxValues: BBox[] = [];
    plantations.forEach((plantation) => {
      const plantationId = plantation.plantation_code;
      if (!this.map.getSource(plantationId) && plantation?.mapData) {
        const feature: any = plantation.mapData;

        this.map.addSource(plantationId, {
          type: 'geojson',
          data: feature as any
        });

        if (plantation.mapData.type === GeoJsonTypesEnum.POINT) {
          this.map.addLayer({
            id: plantationId,
            type: 'circle',
            source: plantationId,
            paint: {
              'circle-radius': 6,
              'circle-color': '#89A9FC'
            },
            filter: ['==', '$type', 'Point']
          });
        } else {
          this.map.addLayer({
            id: plantationId,
            type: 'fill',
            source: plantationId,
            paint: {
              'fill-color': '#89A9FC',
              'fill-opacity': 0.5
            }
          });
        }

        this.showPlantationMarkers(plantation, feature);

        if (this.isPolygonFirstInitialized) {
          boundingBoxValues.push(bbox(feature));
        }
      }
    });
    if (boundingBoxValues.length && this.isInitialLoad) {
      this.zoomToFitBounds(boundingBoxValues);
    }
    this.isInitialLoad = false;
  }

  showPlantationMarkers(plantation: Plantation, feature: any) {
    const hoverPopup = this.getPopup(plantation.plantation_name, false, false);
    const centerCoordinates =
      feature.type === 'Point'
        ? feature.coordinates
        : this.findCenterCoordinate(feature.coordinates);
    if (plantation.mapData) {
      plantation.mapData.centerCoordinates = centerCoordinates;
    }
    const marker = new Marker().setLngLat(centerCoordinates).addTo(this.map);
    this.markerCoordinates.add(marker);
    this.map.on('mouseover', plantation.plantation_code, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
      hoverPopup.setLngLat(centerCoordinates).addTo(this.map);
    });

    this.map.on('mouseleave', plantation.plantation_code, () => {
      this.map.getCanvas().style.cursor = '';
      hoverPopup.remove();
    });
  }

  getPopup(text: string, closeButton: boolean, closeOnClick: boolean) {
    return new Popup({
      closeButton: closeButton,
      closeOnClick: closeOnClick
    }).setHTML(text);
  }

  findCenterCoordinate(coordinates: number[][][]): LngLatLike {
    const numPoints = coordinates[0].length;
    const avgLatitude =
      coordinates[0]?.reduce((sum, point) => sum + point[1], 0) / numPoints;
    const avgLongitude =
      coordinates[0]?.reduce((sum, point) => sum + point[0], 0) / numPoints;
    return [avgLongitude, avgLatitude];
  }

  zoomToFitBounds(boundingBoxValues: any[]) {
    const bounds = new LngLatBounds();
    boundingBoxValues.forEach((boundingBox) => {
      bounds.extend(boundingBox);
    });

    this.map.fitBounds(bounds, { padding: 40 });
  }

  listenToZoomPlantation() {
    this.eventStateService.zoomPlantation$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((plantation) => {
        const feature = plantation?.mapData;
        if (feature) {
          const bounds = new LngLatBounds();
          bounds.extend(bbox(feature) as any);
          if (feature?.type === 'Point') {
            this.map.fitBounds(bounds, { padding: 80, maxZoom: 11 });
          } else {
            this.map.fitBounds(bounds, { padding: 80 });
          }
        }
      });
  }
}
