import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output
} from '@angular/core';

@Directive({
  selector: '[appFileDnd]',
  standalone: true
})
export class FileDndDirective {
  @Output() fileDropped: EventEmitter<File[]> = new EventEmitter<File[]>();
  @HostBinding('class.fileover') fileOver!: boolean;

  @HostListener('dragover', ['$event']) OnDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    this.fileDropped.emit(files);
  }
}
